import { Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';

import { Evaluation } from './evaluation.model';
import { environment } from './../../environments/environment';
//import { AuthService } from '../auth/service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EvaluationsService {
  error = new Subject<string>();
  skippedToday = false;
  allEvaluations?: Observable<Evaluation[]> | null = null;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
   //private authService: AuthService,
    private router: Router
  ) {
    // Have they taken today's evaluation?
  /*  this.authService.user.asObservable().subscribe((data) => {
      if (data) {
        this.fetchAll().subscribe((responseData) => {
          if (this.checkToday(responseData).length < 1) {
            this.router.navigate(['/evaluations', 'evaluate-today']);
          }
        });
      } else {
        // no user so clear the cache
        this.clearCache();
        console.log('cache cleared');
      }
    });*/
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/evaluations/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const evaluation = new Evaluation(
            responseData.evaluation_id,
            responseData.userId,
            responseData.date_taken,
            responseData.breathlessness_now,
            responseData.breathlessness_24,
            responseData.distress,
            responseData.health,
            responseData.today,
            responseData.since,
            responseData.zone,
            responseData.created,
            responseData.modified
          );
          return evaluation;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchEvaluations(status?: number, date_taken?: string) {
    let searchParams = new HttpParams();
    if (status) {
      searchParams = searchParams.append('status', status.toString());
    }
    if (date_taken) {
      searchParams = searchParams.append('date_taken', date_taken.toString());
    }
    // make them order by date_taken asc
    searchParams = searchParams.append('sort', 'date_taken');
    searchParams = searchParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + '/evaluations', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const EvaluationArray: Evaluation[] = [];
          responseData['_embedded'].evaluations.forEach((evaluation) => {
            EvaluationArray.push(evaluation);
          });
          return EvaluationArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchAll() {
    if (!this.allEvaluations) {
      this.allEvaluations = this.http
        .get<any>(environment.apiUrl + '/evaluations', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: Evaluation[] = [];
            responseData['_embedded'].evaluations.forEach((item: any) => {
              // parse the exercise data
              item.day_taken = this.datePipe.transform(item.date_taken, 'yyyy-MM-dd');
              returnArray.push(item);
            });
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allEvaluations;
  }

  clearCache() {
    this.allEvaluations = null;
  }

  checkToday(evaluations:any) {
    const today = new Date();
    let doneToday:any = [];
    evaluations?.forEach((evaluation: any) => {
      if (
        this.datePipe.transform(evaluation.date_taken, 'yyyy-MM-dd') ==
        this.datePipe.transform(today, 'yyyy-MM-dd')
      ) {
        doneToday = evaluation;
      }
    });

    return doneToday;
  }

  createEvaluation(
    breathlessness_now,
    breathlessness_24,
    distress,
    health,
    today,
    since,
    zone
  ) {
    this.clearCache();
    const todayDate = new Date();
    const evaluationData = {
      breathlessness_now: breathlessness_now,
      breathlessness_24: breathlessness_24,
      distress,
      health,
      today,
      since,
      zone,
      date_taken: this.datePipe.transform(todayDate, 'yyyy-MM-dd HH:mm:ss'),
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + '/evaluations',
      evaluationData,
      {
        observe: 'response',
      }
    );
  }

  updateEvaluation(evaluation: Evaluation) {
    this.clearCache();
    const today = new Date();
    const evaluationData = {
      breathlessness_now: evaluation.breathlessness_now,
      breathlessness_24: evaluation.breathlessness_24,
      distress: evaluation.distress,
      health: evaluation.health,
      today: evaluation.today,
      since: evaluation.since,
      zone: evaluation.zone,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + '/evaluations/' + evaluation.evaluation_id,
      evaluationData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/evaluations/' + id
    );
  }
}
