export const environment = {
  production: true,
  apiUrl: 'https://test-api.self-breathe.co.uk',
  staticUrl: 'https://test-static.self-breathe.co.uk',
  title : 'SELF-BREATHE',
  vapidKeys: {
    publicKey:
      'BLVm7PDZO36gFs9Htp0WrY7Rb9vmAycNFzKFdKYciNwbK5pyKP3j0dLZl7tHAiWLYHuFEwnTEdj_kS9pt4zKcbw',
    privateKey: 'Lg1JdtWU9Vz4V0yF41j63fy-Aw3tNiFngmriqD2kYc4',
  },
  avatarPath : '/assets/avatar/',
  testSite: false,
  projectName: 'SELF-BREATHE',
  pwaName: 'SELF-BREATHE',
  languages : [
    {id: 1, language:'English', languageNative: 'English', translationFile : 'en'},
  ],
  chartDefaults: {
    colors: ['#206ba4', '#E85F7C'],
    fontFamily: "'Poppins', sans-serif",
    fontSize: 14,
    fontColor: '#333',
    fontStyle: '800',
  },
  ga:'',
  cookieDomain:'self-breathe.co.uk'
};