<div>
  <div class="container-fluid content-container fh login">
    <img src="/assets/img/logo-self-breathe-light.svg" alt="self-breathe logo" class="logo" width="200">
    <h2>Reset Password</h2>
    <div class="row">
      <div class="col-md-4 offset-md-4">

        <div class="alert alert-danger" *ngIf="error">
          <p>{{ error }}</p>
        </div>
        <div *ngIf="isLoading" class="text-center">
          <app-loading-spinner></app-loading-spinner>
        </div>
        <form
          #authForm="ngForm"
          (ngSubmit)="onSubmit(authForm)"
          *ngIf="!isLoading"
        >
          <div
            ngModelGroup="passwords"
            #passwords="ngModelGroup"
            appCheckPassword
          >
            <div class="form-group">
              <label for="password"
                >Set Password: <span class="required">*</span></label
              >
              <input
                type="password"
                id="password"
                class="form-control"
                name="password"
                placeholder="Please enter your password"
                required
                pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}"
                [ngClass]="{ 'is-invalid': password.dirty && !password.valid }"
                #password="ngModel"
                [(ngModel)]="password1"
              />
              <hr />
              <label for="passwordConfirm"
                >Confirm Password: <span class="required">*</span></label
              >
              <input
                type="password"
                id="passwordConfirm"
                class="form-control"
                name="passwordConfirm"
                placeholder="Please confirm your password"
                required
                [ngClass]="{
                  'is-invalid': passwordConfirm.dirty && !passwordConfirm.valid
                }"
                #passwordConfirm="ngModel"
                [(ngModel)]="password2"
              />

              <div
                *ngIf="
                  passwords.errors?.passwordCheck &&
                  (passwordConfirm.dirty || passwordConfirm.touched)
                "
                class="alert alert-danger"
              >
                Passwords do not match.
              </div>
              <hr />
              <p>Your password must contain the following</p>
              <ul>
                <li
                  [ngClass]="{
                    'text-danger':
                      password.dirty && password.viewModel.length < 8,
                    'text-success':
                      password.dirty && password.viewModel.length > 7
                  }"
                >
                  At least 8 characters
                  <span class="icon-wrap" *ngIf="password.viewModel.length > 7"
                    ><fa-icon
                      [icon]="['far', 'check']"
                      [fixedWidth]="true"
                    ></fa-icon></span
                  ><span
                    class="icon-wrap"
                    *ngIf="password.dirty && password.viewModel.length < 8"
                    ><fa-icon
                      [icon]="['far', 'times']"
                      [fixedWidth]="true"
                    ></fa-icon
                  ></span>
                </li>
                <li
                  [ngClass]="{
                    'text-danger':
                      password.dirty &&
                      !checkPassword(password.viewModel, 'upper'),
                    'text-success':
                      password.dirty &&
                      checkPassword(password.viewModel, 'upper')
                  }"
                >
                  An uppercase character (A-Z)
                  <span
                    class="icon-wrap"
                    *ngIf="checkPassword(password.viewModel, 'upper')"
                    ><fa-icon
                      [icon]="['far', 'check']"
                      [fixedWidth]="true"
                    ></fa-icon></span
                  ><span
                    class="icon-wrap"
                    *ngIf="
                      password.dirty &&
                      !checkPassword(password.viewModel, 'upper')
                    "
                    ><fa-icon
                      [icon]="['far', 'times']"
                      [fixedWidth]="true"
                    ></fa-icon
                  ></span>
                </li>
                <li
                  [ngClass]="{
                    'text-danger':
                      password.dirty &&
                      !checkPassword(password.viewModel, 'lower'),
                    'text-success':
                      password.dirty &&
                      checkPassword(password.viewModel, 'lower')
                  }"
                >
                  A lowercase character (a-z)
                  <span
                    class="icon-wrap"
                    *ngIf="
                      password.dirty &&
                      checkPassword(password.viewModel, 'lower')
                    "
                    ><fa-icon
                      [icon]="['far', 'check']"
                      [fixedWidth]="true"
                    ></fa-icon></span
                  ><span
                    class="icon-wrap"
                    *ngIf="
                      password.dirty &&
                      !checkPassword(password.viewModel, 'lower')
                    "
                    ><fa-icon
                      [icon]="['far', 'times']"
                      [fixedWidth]="true"
                    ></fa-icon
                  ></span>
                </li>
                <li
                  [ngClass]="{
                    'text-danger':
                      password.dirty &&
                      !checkPassword(password.viewModel, 'num'),
                    'text-success':
                      password.dirty && checkPassword(password.viewModel, 'num')
                  }"
                >
                  A digit (0-9)<span
                    class="icon-wrap"
                    *ngIf="
                      password.dirty && checkPassword(password.viewModel, 'num')
                    "
                    ><fa-icon
                      [icon]="['far', 'check']"
                      [fixedWidth]="true"
                    ></fa-icon></span
                  ><span
                    class="icon-wrap"
                    *ngIf="
                      password.dirty &&
                      !checkPassword(password.viewModel, 'num')
                    "
                    ><fa-icon
                      [icon]="['far', 'times']"
                      [fixedWidth]="true"
                    ></fa-icon
                  ></span>
                </li>
                <li
                  [ngClass]="{
                    'text-danger':
                      password.dirty &&
                      !checkPassword(password.viewModel, 'special'),
                    'text-success':
                      password.dirty &&
                      checkPassword(password.viewModel, 'special')
                  }"
                >
                  A special character ($@!%*?)<span
                    class="icon-wrap"
                    *ngIf="
                      password.dirty &&
                      checkPassword(password.viewModel, 'special')
                    "
                    ><fa-icon
                      [icon]="['far', 'check']"
                      [fixedWidth]="true"
                    ></fa-icon></span
                  ><span
                    class="icon-wrap"
                    *ngIf="
                      password.dirty &&
                      !checkPassword(password.viewModel, 'special')
                    "
                    ><fa-icon
                      [icon]="['far', 'times']"
                      [fixedWidth]="true"
                    ></fa-icon
                  ></span>
                </li>
              </ul>
              <div
                class="alert alert-danger"
                *ngIf="!password.valid && password.touched"
              >
                Your password is invalid
              </div>
            </div>
          </div>

          <button
            type="submit"
            [disabled]="!authForm.valid"
            class="btn btn-primary w-100 btn-lg"
          >
            Submit
          </button>
        </form>
        <hr />
        <span
          >Don’t have an account?
          <a [routerLink]="['/register']">Sign up now</a></span
        >
      </div>
    </div>
  </div>
</div>
