<app-subnav [title]="'Welcome to SELF-BREATHE, why and how to use SELF-BREATHE'" [section]="'video-library'"></app-subnav>

<div
  class="container content-wrap mobile-footer-padding relative text-page-container"
>
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="row" >
    <div class="col">
      <video
      width="100%"
        controls
        src="{{ assetPath }}/Self Breathe - Intervention Promo.mp4"
        type="video/mp4"
      ></video>

      <button class="btn btn-primary mt-3" [routerLink]="['/dashboard']">
        Continue
      </button>
    </div>
  </div>
</div>
