<div class="accordion" id="videoSubnav">
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/intro']"
        routerLinkActive="active"
        #linkOverview="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
      >
      Welcome to SELF-BREATHE, why and how to use SELF-BREATHE
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/welcome']"
        routerLinkActive="active"
        #linkOverview="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
      >
      Understanding chronic breathlessness
      </button>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <button
        class="btn btn-link"
        type="button"
        [routerLink]="['/patient-feedback']"
        routerLinkActive="active"
        #linkOverview="routerLinkActive"
        [routerLinkActiveOptions]="{ exact: true }"
      >
      Patients experiences of using SELF-BREATHE
      </button>
    </div>
  </div>
</div>