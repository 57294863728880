import { forkJoin } from 'rxjs';
import { UserSettingsService } from './../../services/user-settings.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ExercisesService } from '../exercises.service';
import { Exercise } from '../exercise.model';
import { WorkoutsService } from './../workouts.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-workout',
  templateUrl: './create-workout.component.html',
  styleUrls: ['./create-workout.component.scss'],
})
export class CreateWorkoutComponent implements OnInit {
  @ViewChild('repDataEl') repDataEl: ElementRef;
  exercises: Exercise[];
  step = 0;
  selectedExercises = [];
  repData = {};
  submitted = false;
  error: string;
  userSettings;
  environment = environment;

  constructor(
    private exercisesService: ExercisesService,
    private workoutsService: WorkoutsService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {


    forkJoin({
      exercises: this.exercisesService.fetchExercises(),
      userSettings: this.userSettingsService.fetchAll()
    }).subscribe((responseData) => {
      this.exercises = responseData.exercises;
      this.userSettings = responseData.userSettings;
    });

  }

  selectExercise(exercise: Exercise): void {
    if (this.selectedExercises.indexOf(exercise.exercise_id) === -1) {
      this.selectedExercises.push(exercise.exercise_id);
    } else {
      const index = this.selectedExercises.indexOf(exercise.exercise_id);
      if (index > -1) {
        this.selectedExercises.splice(index, 1);
      }
    }
  }

  selectAll(type: string): void {
    this.exercises.forEach((exercise) => {
      if (exercise.exercise_type == type) {
        if (this.selectedExercises.indexOf(exercise.exercise_id) === -1) {
          this.selectedExercises.push(exercise.exercise_id);
        }
      }
    });
  }

  countByExerciseType(type: string): number {
    let count = 0;
    if (this.exercises) {
      this.exercises.forEach((exercise) => {
        if (type == exercise.exercise_type) {
          if (this.selectedExercises.indexOf(exercise.exercise_id) !== -1) {
            count++;
          }
        }
      });
    }
    return count;
  }

  nextStep() {
    this.step = this.step + 1;
    this.repDataEl.nativeElement.focus();
    // do we need to save?
    if (this.step > this.selectedExercises.length) {
      this.submitted = true;
      const workout_date = moment().format('YYYY-MM-DD');

      const repsData = {
        bed: { total: 0, data: {} },
        sitting: { total: 0, data: {} },
        standing: { total: 0, data: {} },
      };

      this.exercises.forEach((exercise) => {
        if (exercise.exercise_type === 'bed') {
          if (this.repData[exercise.exercise_id]) {
            repsData.bed.total += +this.repData[exercise.exercise_id];
            repsData.bed.data[exercise.exercise_id] = +this.repData[
              exercise.exercise_id
            ];
          }
        }
      });
      this.exercises.forEach((exercise) => {
        if (exercise.exercise_type === 'sit') {
          if (this.repData[exercise.exercise_id]) {
            repsData.sitting.total += +this.repData[exercise.exercise_id];
            repsData.sitting.data[exercise.exercise_id] = +this.repData[
              exercise.exercise_id
            ];
          }
        }
      });
      this.exercises.forEach((exercise) => {
        if (exercise.exercise_type === 'stand') {
          if (this.repData[exercise.exercise_id]) {
            repsData.standing.total += +this.repData[exercise.exercise_id];
            repsData.standing.data[exercise.exercise_id] = +this.repData[
              exercise.exercise_id
            ];
          }
        }
      });

      const repsDataFinal = {
        bed: repsData.bed.data,
        sitting: repsData.sitting.data,
        standing: repsData.standing.data,
      };
      this.workoutsService
        .saveWorkout(
          workout_date,
          +repsData.bed.total,
          +repsData.standing.total,
          +repsData.sitting.total,
          JSON.stringify(repsDataFinal),
          +repsData.standing.total +
            +repsData.sitting.total +
            +repsData.bed.total
        )
        .subscribe(
          (responseData) => {
            // it's done
            this.userSettingsService
              .saveSetting('doWorkout', 1)
              .subscribe((responeData) => {});
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }
}
