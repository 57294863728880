import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../auth/service/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() pageTitle;
  @Input() tallNav;
  @Input() home;

  constructor(
    private authService: AuthService,
    private location: Location,
    public router: Router
  ) {}

  ngOnInit(): void {}

  logout(): void {
    this.authService.logout();
  }
  onBack() {
    this.location.back();
  }
}
