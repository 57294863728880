<app-subnav [title]="'Today\'s Evaluation'" [section]="'evaluate'"></app-subnav>
<div class="container content-wrap-fluid mobile-footer-padding content-container bg-white fh-nav">
  <div class="evaluation-container">
    <div *ngIf="step == 1">
      <h4>Q1</h4>
      <h3>How is your breathlessness right now?</h3>
      <div class="move-slider">Move the slider</div>
      <div class="custom-slider">
        <img src="/assets/img/scale.svg" class="img-scale" />
        <ngx-slider
          [(value)]="q1Value"
          [options]="q1Options"
          (userChangeStart)="onUserChangeStart(1, $event)"
        ></ngx-slider>
      </div>
      <div class="slider-scale">
        <span class="icon-wrap icon-left">
          <fa-icon [icon]="['far', 'smile']"></fa-icon>
          <span class="scale-label">No Breathlessness</span>
        </span>
        <span class="scale-line-left"></span
        ><span class="scale-line-right"></span>
        <span class="icon-wrap icon-right">
          <fa-icon [icon]="['far', 'tired']"></fa-icon>
          <span class="scale-label">Worst Possible Breathlessness</span>
        </span>
      </div>
      <div
        class="circle mt-5 mb-5"
        [ngClass]="{
          'bg-success': q1Value < 4,
          'bg-warning': q1Value > 3 && q1Value < 8,
          'bg-danger': q1Value > 7
        }"
      >
        {{ q1Value }}
      </div>
    </div>

    <div *ngIf="step == 2">
      <h4>Q2</h4>
      <h3>
        How would you rate your WORST level of breathlessness over the last 24
        hours?
      </h3>
      <div class="move-slider">Move the slider</div>
      <div class="custom-slider">
        <img src="/assets/img/scale.svg" class="img-scale" />
        <ngx-slider
          [(value)]="q2Value"
          [options]="q2Options"
          (userChangeStart)="onUserChangeStart(2, $event)"
        ></ngx-slider>
      </div>
      <div class="slider-scale">
        <span class="icon-wrap icon-left">
          <fa-icon [icon]="['far', 'smile']"></fa-icon>
          <span class="scale-label">No Breathlessness</span>
        </span>
        <span class="scale-line-left"></span
        ><span class="scale-line-right"></span>
        <span class="icon-wrap icon-right">
          <fa-icon [icon]="['far', 'tired']"></fa-icon>
          <span class="scale-label">Worst Possible Breathlessness</span>
        </span>
      </div>
      <div
        class="circle mt-5 mb-5"
        [ngClass]="{
          'bg-success': q2Value < 4,
          'bg-warning': q2Value > 3 && q2Value < 8,
          'bg-danger': q2Value > 7
        }"
      >
        {{ q2Value }}
      </div>
    </div>

    <div *ngIf="step == 3">
      <h4>Q3</h4>
      <h3>
        Over the last 24 hours, how was your distress due to breathlessness?
      </h3>
      <div class="move-slider">Move the slider</div>
      <div class="custom-slider">
        <img src="/assets/img/scale.svg" class="img-scale" />
        <ngx-slider
          [(value)]="q3Value"
          [options]="q3Options"
          (userChangeStart)="onUserChangeStart(3, $event)"
        ></ngx-slider>
      </div>
      <div class="slider-scale">
        <span class="icon-wrap icon-left">
          <fa-icon [icon]="['far', 'smile']"></fa-icon>
          <span class="scale-label">None</span>
        </span>
        <span class="scale-line-left"></span
        ><span class="scale-line-right"></span>
        <span class="icon-wrap icon-right">
          <fa-icon [icon]="['far', 'tired']"></fa-icon>
          <span class="scale-label">Worst</span>
        </span>
      </div>
      <div
        class="circle mt-5 mb-5"
        [ngClass]="{
          'bg-success': q3Value < 4,
          'bg-warning': q3Value > 3 && q3Value < 8,
          'bg-danger': q3Value > 7
        }"
      >
        {{ q3Value }}
      </div>
    </div>
    <div *ngIf="step == 4">
      <h4>Q4</h4>
      <h3>How is your overall health today?</h3>
      <div class="move-slider">Move the slider</div>
      <div class="custom-slider-v">
        <div class="slider-scale slider-scale-v">
          <span class="icon-left">
            <span class="scale-label">Best health I can imagine</span>
            <span class="icon-wrap">
              <fa-icon [icon]="['far', 'smile']"></fa-icon>
            </span>
          </span>
          <span class="scale-line-left"></span
          ><span class="scale-line-right"></span>
          <span class="icon-right">
            <span class="scale-label">Worst health you can imagine </span>
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'tired']"></fa-icon
            ></span>
          </span>
        </div>
        <img src="/assets/img/scale-v.svg" class="img-scale" />
        <ngx-slider
          [(value)]="q4Value"
          [options]="q4Options"
          (userChangeStart)="onUserChangeStart(4, $event)"
        ></ngx-slider>
      </div>

      <div
        class="circle mt-4 mb-4"
        [ngClass]="{
          'bg-danger': q4Value < 40,
          'bg-warning': q4Value > 39 && q4Value < 80,
          'bg-success': q4Value > 79
        }"
      >
        {{ q4Value }}
      </div>
    </div>

    <div *ngIf="step == 5">
      <h4>Q5</h4>
      <div class="text-left">
      <div class="form-group radio-group mb-5">
        
      <h3>Which of these statements best describes how you feel today?</h3>
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            
            name="q5Value"
            [value]="1"
            [(ngModel)]="q5Value"
            id="q5Value1"
          />
          <label class="form-check-label" for="q5Value1">
            <span class="icon-wrap">
              <span class="unselected">
                <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
              </span>
              <span class="selected">
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </span>
            I am feeling ok, my breathing and breathlessness is normal for me
          </label>
        </div>

        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            
            name="q5Value"
            [value]="2"
            [(ngModel)]="q5Value"
            id="q5Value2"
          />
          <label class="form-check-label" for="q5Value2">
            <span class="icon-wrap">
              <span class="unselected">
                <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
              </span>
              <span class="selected">
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </span>
            I feel my breathing and breathlessness is worse
          </label>
        </div>


        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            
            name="q5Value"
            [value]="3"
            [(ngModel)]="q5Value"
            id="q5Value3"
          />
          <label class="form-check-label" for="q5Value3">
            <span class="icon-wrap">
              <span class="unselected">
                <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
              </span>
              <span class="selected">
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </span>
            I feel my breathing is difficult and my breathlessness is really bad
          </label>
        </div>

        
      </div>
      </div>
    </div>


    <div *ngIf="step == 6">
      <h4>Q6</h4>
      <div class="text-left">
      <div class="form-group radio-group mb-5">
        
      <h3>Since your last evaluation, which best describes your activity levels?</h3>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="q6Value"
          [value]="5"
          [(ngModel)]="q6Value"
          id="q6Value5"
        />
        <label class="form-check-label" for="q6Value5">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          A lot less
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="q6Value"
          [value]="4"
          [(ngModel)]="q6Value"
          id="q6Value4"
        />
        <label class="form-check-label" for="q6Value4">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          Slightly less
        </label>
      </div>
        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            
            name="q6Value"
            [value]="1"
            [(ngModel)]="q6Value"
            id="q6Value1"
          />
          <label class="form-check-label" for="q6Value1">
            <span class="icon-wrap">
              <span class="unselected">
                <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
              </span>
              <span class="selected">
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </span>
            About the same
          </label>
        </div>

        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            
            name="q6Value"
            [value]="2"
            [(ngModel)]="q6Value"
            id="q6Value2"
          />
          <label class="form-check-label" for="q6Value2">
            <span class="icon-wrap">
              <span class="unselected">
                <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
              </span>
              <span class="selected">
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </span>
            Slightly higher
          </label>
        </div>


        <div class="form-check">
          <input
            type="radio"
            class="form-check-input"
            
            name="q6Value"
            [value]="3"
            [(ngModel)]="q6Value"
            id="q6Value3"
          />
          <label class="form-check-label" for="q6Value3">
            <span class="icon-wrap">
              <span class="unselected">
                <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
              </span>
              <span class="selected">
                <fa-icon
                  [icon]="['fas', 'check-square']"
                  [fixedWidth]="true"
                ></fa-icon>
              </span>
            </span>
            A lot higher
          </label>
        </div>


      </div>
      </div>
    </div>

    

    <div *ngIf="step < 7">
      <button
        class="btn-primary btn btn-block btn-lg"
        (click)="next()"
        [disabled]="(!changed[step] && step<5) && !evaluation"
      >
        <span *ngIf="step < 6">Next</span>
        <span *ngIf="step > 5">Complete</span>
      </button>

      <div class="text-center mt-3">
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step == 1, 'text-menu-bg': step != 1 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step == 2, 'text-menu-bg': step != 2 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step == 3, 'text-menu-bg': step != 3 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step == 4, 'text-menu-bg': step != 4 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step == 5, 'text-menu-bg': step != 5 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
        <span
          class="icon-wrap mr-1"
          [ngClass]="{ 'text-primary': step == 6, 'text-menu-bg': step != 6 }"
          ><fa-icon [icon]="['fas', 'circle']"></fa-icon
        ></span>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="error">
      An error occured : {{ error }}
    </div>

    <div *ngIf="step == 7">
      <div [ngSwitch]="zone">
        <div *ngSwitchCase="'r'">
          <div class="circle bg-danger mt-3 mb-3">&nbsp;</div>
          <h3>You are in the Red Zone</h3>
          <p class="text-left">
            You are in the Red Zone.  You URGENTLY need to contact your GP or call 111 for advice medical advice 
          </p>
          <p class="text-left">
            In the RED ZONE if you have any any of the following serious symptoms call 999:
          </p>
          <ul class="info-list mt-3 mb-3">
            <li>
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
              ></span>
              You are extremely breathless or having difficulty breathing
            </li>
            <li>
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
              ></span>
              You feel drowsy or confused
            </li>
            <li>
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
              ></span>
              You have chest pain
            </li>
          </ul>
        </div>
        <div *ngSwitchCase="'a'">
          <div class="circle bg-warning mt-3 mb-3">&nbsp;</div>
          <h3>You are in the Amber Zone</h3>

          <ul class="info-list mt-3 mb-3">
            <li>
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
              ></span>
              You feel your symptoms are getting worse and you don’t feel OK.
            </li>
            <li>
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'check']"></fa-icon
              ></span>
              Keep monitoring how you feel.
            </li>
            <li>
              <span class="icon-wrap"
                ><fa-icon [icon]="['fas', 'check']"></fa-icon
              ></span>
              If your symptoms improve, continue with your usual medication and
              your breathing and SELF-BREATHE exercises as normal.
            </li>
            <li>
              <span class="icon-wrap"
                ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
              ></span>
              If you are concerned by your symptoms and / or they have lasted more than 24 hours, you must call your GP / or 111 for medical advice
            </li>
          </ul>
        </div>
        <div *ngSwitchCase="'g'">
          <div class="circle bg-success mt-3 mb-3">&nbsp;</div>
          <h3>You are in the Green Zone</h3>
          <p class="text-left">
            You are in the Green Zone when your stable and you feel at your
            best. If your symptoms are in the Green Zone, it means that they are
            under control. You should continue with your usual medication and
            your SELF-BREATHE exercises as normal.
          </p>
        </div>
      </div>

      <button class="btn-primary btn btn-block btn-lg" [routerLink]="['/']">
        Go To Home
      </button>

    </div>

    <div class="text-center mt-3" *ngIf="step != 5 && (allEvaluations && allEvaluations.length>0)">
      <a (click)="skip()" class="cursor">Skip Evaluation</a>
    </div>
  </div>
</div>
