import { Component } from '@angular/core';

@Component({
  selector: 'app-subnav-video-library',
  templateUrl: './subnav-video-library.component.html',
  styleUrls: ['./subnav-video-library.component.scss']
})
export class SubnavVideoLibraryComponent {

}
