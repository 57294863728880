import { Component, OnInit, Input, Renderer2, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-subnav',
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.scss'],
})
export class SubnavComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() section: string;
  @Input() hideMenu: boolean = false;
  showMenu = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}
  toggleMenu(): void {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.renderer.addClass(document.body, 'ovh');
    } else {
      this.renderer.removeClass(document.body, 'ovh');
    }
  }
  onToggleMenu(): void {
    this.showMenu = !this.showMenu;
    if (this.showMenu) {
      this.renderer.addClass(document.body, 'ovh');
    } else {
      this.renderer.removeClass(document.body, 'ovh');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'ovh');
  }
}
