import { DateHelperService } from "src/app/services/date-helper.service";
import { CacheManagerService } from "./../../services/cache-manager.service";
import { UserService } from "./user.service";
import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { catchError, tap, take, exhaustMap, map, last } from "rxjs/operators";
import { throwError, BehaviorSubject } from "rxjs";

import { CookieService } from "ngx-cookie-service";
import { environment } from "../../../environments/environment";
import { User } from "../model/user.model";

export interface AuthResponseData {
  kind: string;
  user: {
    email: string;
    id: string;
    app_token: string;
    user: {};
    api_token: { access_token: string; expires_in: number };
    avatar: string;
    language_id: string;
    week_start: string;
    username: string;
    password_change:string;
    created:string;
  };
  id: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {
  user = new BehaviorSubject<any>(null);
  private tokenExpirationTimer: any;
  public redirect!: string;
  public fetching = new BehaviorSubject<any>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private userService: UserService,
    private cacheManagerService: CacheManagerService,
    private dateHelperService: DateHelperService,
  ) {}

  login(username: string, password: string, rememberMe: any) {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + "/auth", {
        username,
        password,
        rememberMe,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.username,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );

          // are we remebering it?
          if (rememberMe) {
            // this.autoLogout(expiresIn * 1000);
            const now = new Date();
            now.setMonth(now.getMonth() + 1);
            const exp = new Date(now);

            // store in cookies
            this.cookieService.set(
              "appToken",
              resData.user.app_token,
              exp,
              "/"
            );
            this.cookieService.set("appEmail", resData.user.email, exp, "/");
          }
        })
      );
  }

  forgottenPassword(email: string) {
    return this.http
      .post<any>(environment.apiUrl + "/forgotten_password", { email })
      .pipe(catchError(this.handleError));
  }

  checkForgottenPasswordHash(id: any, hash: string) {
    const params = new HttpParams().set("user_id", id).set("hash", hash);
    return this.http
      .get<any>(environment.apiUrl + "/reset_password", {
        params,
        responseType: "json",
      })
      .pipe(
        tap((resData) => {
          return resData;
        })
      );
  }

  resetPassword(user_id: number, token: string, password: string) {
    return this.http
      .post<any>(environment.apiUrl + "/reset_password", {
        user_id,
        token,
        password,
      })
      .pipe(catchError(this.handleError));
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.http
      .post<any>(environment.apiUrl + "/change_password", {
        current_password: currentPassword,
        new_password: newPassword,
      })
      .pipe(catchError(this.handleError),
      tap((resData)=>{
        this.cacheManagerService.clearAllCache();
          this.userService.reset();
          this.userService.get().subscribe((userdata) => {
            return userdata;
          });
      }));
  }

  /*
  checkCode(code: string, email: string) {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + "/check_code", {
        code, email
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {})
      );
  }
  register(
    email: string,
    code:string,
    password: string,
    firstName: string,
    lastName: string,
    username:string,
    smoke:string,
    drink:string,
    surgery_date:string,
    op_area_id:number
  ) {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + "/register", {
        email,
        code,
        password,
        first_name: firstName,
        last_name: lastName, 
        username,
        smoke,
        drink,
        surgery_date,
        op_area_id
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );
        })
      );
  }
*/
  updateDetails(
    email: string,
   // firstName: string,
   // lastName: string,
    notifications:string
  ) {
    return this.http
      .post<any>(environment.apiUrl + "/user_data", {
        email,
       // first_name: firstName,
       // last_name: lastName,
        notifications
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {

          this.cacheManagerService.clearAllCache();
          this.userService.reset();
          this.userService.get().subscribe((userdata) => {
            return userdata;
          });
        })
      );
  }

  upload(formData: any) {
    return this.http.post(environment.apiUrl + "/avatar", formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  checkScreenName(screenName: string) {
    // use params as posting to form
    const params = new HttpParams().set("screenName", "" + screenName);

    return this.http
      .get<any>(environment.apiUrl + "/check-screen-name", {
        params: params,
        responseType: "json",
      })
      .pipe(
        tap((resData) => {
          return resData;
        })
      );
  }

  restore() {
    return this.http
      .get<any>(environment.apiUrl + "/restore", {
        responseType: "json",
      })
      .pipe(
        catchError(this.handleError),
        tap(
          (resData) => {
            const userData = resData._embedded.restore[0];
            // console.log(userData);
            this.handleAuthentication(
              userData.user.username,
              userData.user.id,
              userData.user.app_token,
              userData.user,
              userData.user.api_token.access_token,
              userData.user.api_token.expires_in
            );
            this.fetching.next(false);

            this.userService.reset();
            this.userService.get().subscribe((userdata) => {});
          },
          (error) => {
            this.fetching.next(false);
            return error;
          }
        )
      );
  }

  autoLogin() {
    this.fetching.next(true);
    const appUsername = this.cookieService.get("appUsername");
    const appToken = this.cookieService.get("appToken");

    if (!appToken || !appUsername) {
      this.fetching.next(false);
      return;
    }

    return this.http
      .post<any>(environment.apiUrl + "/restore", {
        username: appUsername,
        token: appToken,
      })
      .pipe(
        catchError(this.handleError),
        tap(
          (resData) => {
            this.handleAuthentication(
              resData.user.username,
              resData.user.id,
              appToken,
              resData.user,
              resData.user.api_token.access_token,
              resData.user.api_token.expires_in
            );
            this.fetching.next(false);
            this.userService.get().subscribe((userdata) => {});
          },
          (error) => {
            this.fetching.next(false);
          }
        )
      );
  }

  getAccessToken() {
    this.user.pipe(
      take(1),
      exhaustMap((user) => {
        if (user) {
          return user.token;
        }
      })
    );
    return null;
  }

  refreshAccessToken() {
    return this.http.get<any>(environment.apiUrl + "/restore").pipe(
      catchError(this.handleError),
      tap((resData) => {
        let restoreData = resData._embedded.restore[0];
        this.handleAuthentication(
          restoreData.user.username,
          restoreData.user.id,
          restoreData.user.app_token,
          restoreData.user,
          restoreData.user.api_token.access_token,
          restoreData.user.api_token.expires_in
        );
        // this.userService.get().subscribe((userdata) => {});
      })
    );
  }
  handleRefresh401(errorRes: HttpErrorResponse) {
    const errorMessage = "An unknown error occurred!";
    return throwError(errorMessage);
  }

  logout(): any {
    this.cookieService.delete("appToken");
    this.cookieService.delete("appEmail");
    // this.userService.userData.next(null);
    // this.userService.reset();
    this.cacheManagerService.clearAllCache();
    this.http
      .get<any>(environment.apiUrl + "/logout", {
        responseType: "json",
      })
      .subscribe(
        (response) => {
          this.router.navigate(["/login"]);
          return false;
        },
        (error) => {
          return error;
        }
      );

    this.user.next(null);
    this.userService.reset();
    
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  private handleAuthentication(
    username: string,
    userId: string,
    token: string,
    userData: {
      username: any;
      avatar: any;
      language_id: any;
      week_start: any;
      password_change:any;
      created:any;
    },
    api_token: string,
    expiresIn: number
  ) {
    this.dateHelperService.setStartOfWeek(userData.week_start);

    environment.languages.forEach((lang) => {
      if (lang.id == userData.language_id) {
     //   this.translate.use(lang.translationFile);
      }
    });

    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(
      username,
      userId,
      token,
      api_token,
      expirationDate,
      userData.avatar,
      userData.language_id,
      userData.week_start,
      userData.username,
      userData.password_change,
      userData.created,

    );
    this.user.next(user);
    this.userService.get().subscribe((userdata) => {});
  }

  private handleError(errorRes: HttpErrorResponse) {
    // console.log(errorRes);
    let errorMessage =
      "An unknown error occurred.  If the problem persists please contact us.";
    if (!errorRes.statusText) {
      return throwError(errorMessage);
    }

    switch (errorRes.statusText) {
      case "EMAIL_EXISTS":
        errorMessage = "This email exists already";
        break;
      case "EMAIL_NOT_FOUND":
        errorMessage = "This email does not exist.";
        break;
      case "INVALID_PASSWORD":
        errorMessage = "This password is not correct.";
        break;
      case "INVALID_CREDENTIALS":
        errorMessage = "The email or password is incorrect.";
        break;
      case "INVALID_REGISTRATION_DATA":
        errorMessage = "Invalid registration data provided.";
        errorMessage += errorRes.error;
        break;
      case "INVALID_DATA":
        errorMessage = errorRes.error;
        break;
      case "ACTION_NOT_ALLOWED":
        errorMessage = errorRes.error;
        break;
      case "ACCOUNT_EXPIRED":
        errorMessage = "Your account has expired and no longer has access.";
        break;
      case "Unprocessable Entity":
        errorMessage = "The data could not be processed.";
        break;
    }
    return throwError(errorMessage);
  }

  saveWebPush(webPushConfig) {
    const postData = {
      webPushConfig,
    };
    return this.http.post<any>(
      environment.apiUrl + '/web_push',
      postData,
      {
        observe: 'response',
      }
    );
  }

  getLoginDays(){
    return this.http
      .get<any>(environment.apiUrl + '/week_logins', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
         
          return  responseData['_embedded'].week_logins;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  checkPassword(password: string, check: string) {
    switch (check) {
      case "upper":
        return /[A-Z]/.test(password);
        break;
      case "lower":
        return /[a-z]/.test(password);
        break;
      case "num":
        return /[0-9]/.test(password);
        break;
      case "special":
        return /[$@$!%*?&]/.test(password);
        break;
    }
    return false;
  }
}
