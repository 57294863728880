import { Injectable } from '@angular/core';
import { EvaluationsService } from '../evaluations/evaluations.service';

@Injectable({
  providedIn: 'root',
})
export class CacheManagerService {
  constructor(private evaluationService: EvaluationsService) {}

  clearAllCache() {
    this.evaluationService.clearCache();
  }
}