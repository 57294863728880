import { UserSettingsService } from './../services/user-settings.service';
import { forkJoin } from 'rxjs';
import { Workout } from './../exercise/workout.model';
import { WorkoutsService } from './../exercise/workouts.service';

import { Component, OnInit, ViewChild } from '@angular/core';
import { GoalsService } from '../goals/goals.service';
import { Goal } from './../goals/goal.model';
import { EvaluationsService } from './../evaluations/evaluations.service';
import { Evaluation } from './../evaluations/evaluation.model';
import { Router } from '@angular/router';
import { SwipeService } from '../shared/swipe.service';
import { SwiperDirective, SwiperComponent } from 'ngx-swiper-wrapper';
import * as moment from 'moment';
import { AuthService } from '../auth/service/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public goals: Goal[] = [];
  public goalsToReview = [];
  public evaluations: Evaluation[] = [];
  public evlauationToday = false;
  public latestEvaluation: Evaluation = null;
  public workouts: Workout[] = [];
  public latestWorkout: Workout;
  public userName: string;
  public errors = [];
  isFetching = false;
  sliderIndex = 0;
  swiperConfig = { effect: 'slide', threshold: 10 };
  userSettings;
  daysSinceEvaluation = -1;
  daysSinceWorkout = -1;
  gettingStartedCompleted = false;
  showLoginWidget = false;

  public assetPath = environment.staticUrl;
  watchedVideo = true;

  statements = [
    {
      id: 1,
      title: 'I am going to use the lean forward recovery position',
      link: '/techniques/49',
    },
    {
      id: 2,
      title:
        'I am going to use the breathing recovery technique; fan, forward lean, focus',
      link: '/techniques/49',
    },
    {
      id: 3,
      title: 'I am going to use my fan Focus and  gently breathing out',
      link: '/techniques/47',
    },
    {
      id: 4,
      title:
        "I am going to self-reassure my myself by saying; I can do this - I am doing it now I don't need to be frightened - I am OK",
      link: '',
    },
    {
      id: 5,
      title: 'I am going to use my fan Focus and use purse lipped breathing',
      link: '/techniques/46',
    },
    { id: 6, title: 'I am not going to panic', link: '' },
    {
      id: 7,
      title: 'I am going to focus on breathing control',
      link: '/techniques/45',
    },
    {
      id: 8,
      title: 'I am going to use the calming hand',
      link: '/techniques/51',
    },
    {
      id: 9,
      title: 'I will read the breathlessness poem',
      link: '/techniques/52',
    },
  ];
  currentPlan;

  sinceLabels: any = {
    1: 'About the same',
    2: 'Slightly higher',
    3: 'A lot higher',
    4: 'Slightly less',
    5: 'A lot less',
  };

  @ViewChild(SwiperComponent, { static: false }) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, { static: false }) directiveRef?: SwiperDirective;

  loginDays: any;
  constructor(
    private goalsService: GoalsService,
    private evaluationsService: EvaluationsService,
    private authService: AuthService,
    private workoutsService: WorkoutsService,
    private router: Router,
    private swipeService: SwipeService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userName = user.first_name;
        if (moment(user.created) < moment().subtract(7, 'd')) {
          this.showLoginWidget = true;
        }

        
      }
    });

    this.isFetching = true;

    forkJoin({
      goals: this.goalsService.fetchGoals(),
      evaluations: this.evaluationsService.fetchAll(),
      workouts: this.workoutsService.fetchWorkouts(),
      userSettings: this.userSettingsService.fetchAll(),
      weekLogins: this.authService.getLoginDays(),
    }).subscribe((responseData) => {
      this.goals = responseData.goals;
      this.loginDays = responseData.weekLogins;
      this.goals.forEach((goal) => {
        if (
          moment(goal.review) <= moment().add(1, 'w').startOf('day') &&
          +goal.status !== 3
        ) {
          this.goalsToReview.push(goal);
        }
      });

      this.evaluations = responseData.evaluations;
      if (this.evaluations.length > 0) {
        this.latestEvaluation = this.evaluations[this.evaluations.length - 1];
      }
      if (this.evaluationsService.checkToday(this.evaluations)?.length > 0) {
        this.evlauationToday = true;
      }

      if (this.latestEvaluation) {
        let b = moment(this.latestEvaluation.date_taken);
        let a = moment();
        this.daysSinceEvaluation = a.diff(b, 'days');
      }
      this.workouts = responseData.workouts;
      if (this.workouts.length > 0) {
        this.latestWorkout = this.workouts[this.workouts.length - 1];

        let b = moment(this.latestWorkout.workout_date);
        let a = moment();
        this.daysSinceWorkout = a.diff(b, 'days');
      }

      this.userSettings = responseData.userSettings;
      if (this.userSettings['watchIntro']) {
        this.sliderIndex = 1;
        if (this.userSettings['takeEvaluation']) {
          this.sliderIndex = 2;
          if (this.userSettings['viewTechniques']) {
            this.sliderIndex = 3;
            if (this.userSettings['setupActionPlan']) {
              this.sliderIndex = 4;
              if (this.userSettings['getActive']) {
                this.sliderIndex = 5;
                if (this.userSettings['aboutGoal']) {
                  this.sliderIndex = 6;
                  if (this.userSettings['patientFeedback']) {
                    this.sliderIndex = 7;
                    this.gettingStartedCompleted = true;
                  }
                }
              }
            }
          }
        }
      }

      // evaluation?

      if(this.gettingStartedCompleted){
        // do they need to take today evaluation?
        let lastSkip = localStorage.getItem('evaluationSkipped');
        // moment().format('YYYY-MM-DD')
        this.evaluationsService.fetchAll().subscribe((responseData) => {
          if (
            this.evaluationsService.checkToday(responseData).length < 1 &&
            (!lastSkip ||
              (lastSkip && lastSkip != moment().format('YYYY-MM-DD')))
          ) {
            this.router.navigate(['/evaluations', 'evaluate-today']);
          }
        });
      }


      if (this.userSettings['videoIntro']) {
        this.watchedVideo = true;
      } else {
        this.watchedVideo = false;
      }

      if (this.userSettings['currentPlan']) {
        this.currentPlan = JSON.parse(this.userSettings['currentPlan']);
      }

      this.isFetching = false;
    });
  }
  onNextTask() {
    this.componentRef.directiveRef.nextSlide();
  }
  onPrevTask() {
    this.componentRef.directiveRef.prevSlide();
  }
  onSetIndex(index) {
    this.sliderIndex = index;
  }
  getLink(statement: any) {
    let link;
    this.statements.forEach((_statement) => {
      if (_statement.id == statement.id && _statement.link) {
        link = _statement.link;
      }
    });
    return link;
  }

  totalLogins(logins) {
    let total = 0;

    logins.forEach((login) => {
      if (login.login) {
        total++;
      }
    });
    return total;
  }

  dismissIntro() {
    this.userSettingsService
      .saveSetting('videoIntro', 1)
      .subscribe((responeData) => {
        this.watchedVideo = true;
      });
  }

  onClickVideo(event: any) {
    event.stopPropagation();
  }
}
