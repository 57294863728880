import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PageViewService {
  error = new Subject<string>();
  slug = '/page_views';
  resourceName = 'page_views';
  allEntries?: Observable<any[]> | null = null;

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<any[]> {
    if (!this.allEntries) {
      this.allEntries = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: any[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allEntries;
  }

  clearCache() {
    this.allEntries = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(page_id: number) {
    this.clearCache();
    const payload = {
      page_id,
    };
    return this.http.post<any>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }
}
