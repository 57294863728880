import { Title } from '@angular/platform-browser';
import { EvaluationsService } from './evaluations/evaluations.service';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { slideInAnimation, fadeInAnimation } from './route-animations';
import { environment } from './../environments/environment';
import { SwPush } from '@angular/service-worker';
import { UserService } from './auth/service/user.service';
import { AuthService } from './auth/service/auth.service';
import { Subscription } from 'rxjs';
import { NgcCookieConsentService, NgcInitializationErrorEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { AnalyticsService } from './services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation, fadeInAnimation],
})
@HostListener('window:resize', ['$event'])
export class AppComponent implements OnInit {
  title = 'self-breathe-app';
  public innerWidth: number;
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializeSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  /* pageTitle: Observable<string>;
  showFooterNav: Observable<boolean>;
  showTopNav: Observable<boolean>;

  private defaultShowFooterNav = true;
  private defaultShowTopNav = true;
  private defaultPageTitle = null;*/

  public currentUser;
  constructor(
    private router: Router,
    private authService: AuthService,
    private evaluationsService: EvaluationsService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    readonly swPush: SwPush,
    private userService:UserService,
    private ccService: NgcCookieConsentService,
    private analyticsService: AnalyticsService,
  ) {}


  ngOnInit() {

    this.userService.userData.subscribe((userDataResponse) => {
      this.currentUser = userDataResponse;
      if(this.currentUser && this.currentUser.password_change == null && this.router.url !='/change-password'){
        this.router.navigate(['/change-password']);
      }
    });

    this.subscribeToPush();
    this.swPush.notificationClicks.subscribe(({ action, notification }) => {
      // console.log(notification);
      // check if there is somehting we need to do?
      if (notification.data?.url) {
        this.router.navigateByUrl(notification.data?.url);
      }
      parent.focus();
      window.focus();
    });

    this.innerWidth = window.innerWidth;
     // try and restpore session
     this.authService.restore().subscribe(
      (restoreResponsedata) => {
        if (this.router.url.split('?')[0] === '/login') {
          if (this.authService.redirect) {
            this.router.navigateByUrl(this.authService.redirect);
          } else {
            this.router.navigate(['/']);
          }
        }
      },
      (errorMessage) => {
        // try autologin here?
        this.authService.autoLogin()?.subscribe(
          (resData) => {
            if (this.router.url.split('?')[0] === '/login') {
              if (this.authService.redirect) {
                this.router.navigateByUrl(this.authService.redirect);
              } else {
                this.router.navigate(['/dashboard']);
              }
            }
          },
          (errorMessage) => {
            // console.log(errorMessage);
          }
        );
      }
    );

    // scroll to top on route change
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      if(this.currentUser && this.currentUser.password_change == null && this.router.url !='/change-password'){
        this.router.navigate(['/change-password']);
      }
      // set page title
      const title = this.getTitle(
        this.router.routerState,
        this.router.routerState.root
      ).join(' | ');
      this.titleService.setTitle(environment.title + ' | ' + title);
      this.analyticsService.trackVirtualPageview(
        environment.title + ' | ' + title
      );
    });

     // cookie consent

     if (this.ccService.hasConsented()) {
      // start tracking
      this.analyticsService.startTracking();
    }
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // you can use this.ccService.getConfig() to do stuff...
    });


    this.statusChangeSubscription = this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
        if (event.status === 'deny') {
          this.analyticsService.stopTracking();
        } else {
          this.analyticsService.startTracking();
        }
      }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(
      () => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.ccService.getConfig() to do stuff...
      }
    );
    // end cookie consent
  }
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  showMobile(routerOutlet) {
    if (this.innerWidth < 400) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData['animation']
      );
    }
  }
  showDesktop(routerOutlet) {
    if (this.innerWidth > 399) {
      return (
        routerOutlet &&
        routerOutlet.activatedRouteData &&
        routerOutlet.activatedRouteData['animation']
      );
    }
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.pageTitle) {
      data.push(parent.snapshot.data.pageTitle);
    }

    if (parent && parent.snapshot.data && parent.snapshot.data.subTitle) {
      data.push(parent.snapshot.data.subTitle);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }


  // push notifications
  private async subscribeToPush() {
    let subSaved = false;
    try {
      const sub = await this.swPush
        .requestSubscription({
          serverPublicKey: environment.vapidKeys.publicKey,
        })
        .then((sub) => {
          if (this.currentUser) {
            this.authService
              .saveWebPush(JSON.stringify(sub.toJSON()))
              .subscribe((responseData) => {
                //console.log(responseData);
                this.userService.reset();
                this.userService.get().subscribe((userData) => {
                  this.currentUser = userData;
                });
                subSaved = true;
              });
          } else {
            this.authService.user.subscribe((user) => {
              if (user && !subSaved) {
                this.authService
                  .saveWebPush(JSON.stringify(sub.toJSON()))
                  .subscribe((responseData) => {
                    //console.log(responseData);
                    this.userService.reset();
                    this.userService.get().subscribe((userData) => {
                      this.currentUser = userData;
                    });
                    subSaved = true;
                  });
              }
            });
          }
        });
      // TODO: Send to server.
    } catch (err) {
      console.error('Could not subscribe due to:', err);
    }
  }
}
