<app-subnav [title]="'Overview'" [section]="'evaluate'"></app-subnav>
    <div class="container mobile-footer-padding content-container">

      <div class="row mt-0">
        <div class="col col-xs">
            <button class="btn btn-primary btn-block" [routerLink]="['/evaluations', 'all']"><span class="icon-wrap"><fa-icon [icon]="['fas', 'traffic-light']"></fa-icon></span> &nbsp;All Evaluations</button>
        </div>
        <div class="col col-xs">
            <button class="btn btn-primary btn-block" [routerLink]="['/evaluations', 'evaluate-today']"><span class="icon-wrap"><fa-icon [icon]="['fas', 'sliders-h']"></fa-icon></span> &nbsp;Take Evaluation</button>
        </div>
      </div>
      <div class="row">
        <div class="col">
            <div class="jumbotron text-center" *ngIf="latestEvaluation">
                <span class="circle bg-success" [ngClass]="{'bg-success': latestEvaluation.zone=='g', 'bg-warning': latestEvaluation.zone=='a', 'bg-danger': latestEvaluation.zone=='r'}">&nbsp;</span>
                <p class="lead">You are in the {{latestEvaluation.zone | zoneToColour | titlecase}} Zone</p>
                <a [routerLink]="['/evaluations', 'view', latestEvaluation.evaluation_id]">View Evaluation</a>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col">

            <h4 class="content-title">
                <span class="aside aside-left">{{days[0] | date : 'd MMM'}} - {{today| date : 'd MMM'}}</span>
                30 Day Activity
                <span class="aside aside-right">{{evaluations.length}} evaluated day<span *ngIf="evaluations.length!=1">s</span></span>
              </h4>

          <div class="card card-widget">
            <div class="card-body card-month">
                <table class="table table-activity">
                    <thead>
                        <tr>
                            <th>M</th>
                            <th>T</th>
                            <th>W</th>
                            <th>T</th>
                            <th>F</th>
                            <th>S</th>
                            <th>S</th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr>
                          <td *ngFor="let count of [0,1,2,3,4,5,6]">
                              <span *ngIf="days[count]>monthAgo" [ngClass]="{circle: (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone, 'bg-success' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'g', 'bg-warning' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'a', 'bg-danger' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'r'}">{{days[count] | date : 'd'}}</span>
                          </td>
                      </tr>
                      <tr>
                          <td *ngFor="let count of [7,8,9,10,11,12,13]">
                              <span [ngClass]="{circle: (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone, 'bg-success' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'g', 'bg-warning' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'a', 'bg-danger' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'r'}">{{days[count] | date : 'd'}}</span>
                          </td>
                      </tr>
                      <tr>
                          <td *ngFor="let count of [14,15,16,17,18,19,20]">
                              <span [ngClass]="{circle: (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone, 'bg-success' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'g', 'bg-warning' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'a', 'bg-danger' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'r'}">{{days[count] | date : 'd'}}</span>
                          </td>
                      </tr>
                      <tr>
                          <td *ngFor="let count of [21,22,23,24,25,26,27]">
                              <span [ngClass]="{circle: (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone, 'bg-success' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'g', 'bg-warning' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'a', 'bg-danger' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'r'}">{{days[count] | date : 'd'}}</span>
                          </td>
                      </tr>
                      <tr>
                          <td *ngFor="let count of [28,29,30,31,32,33,34]">
                              <span *ngIf="days[count]<=today"  [ngClass]="{circle: (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone, 'bg-success' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'g', 'bg-warning' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'a', 'bg-danger' : (evaluations | filterObjectArray : {'day_taken' : days[count]} : true).zone == 'r'}">{{days[count] | date : 'd'}}</span>
                          </td>
                      </tr>
                  </tbody>
                </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
            <h4 class="content-title">Zone Explanations</h4>
          <div class="card card-widget">
            <div class="card-body">
                    <tabset [justified]="true">
                      <tab id="tab1">
                        <ng-template tabHeading>
                            <span class="icon-wrap text-success"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span> &nbsp;Green
                          </ng-template>
                         <div class="text-left">
                           <strong> In the Green Zone you're stable and you feel at your
                            best. If your symptoms are in the Green Zone, it means that they are
                            under control. You should continue with your usual medication and
                            your SELF-BREATHE exercises as normal.</strong>
                          </div>
                    </tab>
                      <tab>
                        <ng-template tabHeading>
                            <span class="icon-wrap text-warning"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span> &nbsp;Amber
                          </ng-template>
                          <div class="text-left">
                            <ul class="info-list mt-3 mb-3">
                              <li>
                                <span class="icon-wrap"
                                  ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
                                ></span>
                                You feel your symptoms are getting worse and you don’t feel OK.
                              </li>
                              <li>
                                <span class="icon-wrap"
                                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                                ></span>
                                Keep monitoring how you feel.
                              </li>
                              <li>
                                <span class="icon-wrap"
                                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                                ></span>
                                If your symptoms improve, continue with your usual medication and
                                your breathing and SELF-BREATHE exercises as normal.
                              </li>
                              <li>
                                <span class="icon-wrap"
                                  ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
                                ></span>
                                If you are concerned by your symptoms and / or they have lasted more than 24 hours, you must call your GP / or 111 for medical advice
                              </li>
                            </ul>
                          </div>
                      </tab>
                      <tab>
                        <ng-template tabHeading>
                            <span class="icon-wrap text-danger"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span> &nbsp;Red
                          </ng-template>
                          <div class="text-left">
                            
                            <p class="text-left">
                              If you are in the Red Zone you URGENTLY need to contact your GP or call 111 for advice medical advice 
                            </p>
                            <p class="text-left">
                              In the RED ZONE if you have any any of the following serious symptoms call 999:
                            </p>
                            <ul class="info-list mt-3 mb-3">
                              <li>
                                <span class="icon-wrap"
                                  ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
                                ></span>
                                You are extremely breathless or having difficulty breathing
                              </li>
                              <li>
                                <span class="icon-wrap"
                                  ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
                                ></span>
                                You feel drowsy or confused
                              </li>
                              <li>
                                <span class="icon-wrap"
                                  ><fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon
                                ></span>
                                You have chest pain
                              </li>
                            </ul>

                          </div>
                      </tab>
                    </tabset>
            </div>
          </div>
        </div>
      </div>
    </div>
