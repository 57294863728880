<nav
  class="navbar navbar-expand-sm navbar-dark navbar-subnav"
><div class="container justify-content-center align-items-start">
  <button class="mr-auto btn-nav-menu" type="button" (click)="toggleMenu()">
    <fa-icon [icon]="['fas', 'bars']" *ngIf="!showMenu"></fa-icon>
    <fa-icon [icon]="['fas', 'times']" *ngIf="showMenu"></fa-icon>
  </button>
  <h2 class="navbar-brand position-absolute">
    <span *ngIf="showMenu">Menu</span><span *ngIf="!showMenu">{{ title }}</span>
  </h2>

</div>
</nav>
<nav class="side-nav fh-nav" *ngIf="showMenu">
  <div class="container justify-content-center align-items-start">
  <app-subnav-exercise *ngIf="section == 'exercise'" (toggleMenu)="onToggleMenu()"></app-subnav-exercise>
  <app-subnav-evaluation *ngIf="section == 'evaluate'"></app-subnav-evaluation>
  <app-subnav-goals *ngIf="section == 'goals'"></app-subnav-goals>
  <app-subnav-resources *ngIf="section == 'resources'" (toggleMenu)="onToggleMenu()"></app-subnav-resources>
  <app-subnav-techniques *ngIf="section == 'techniques'" (toggleMenu)="onToggleMenu()"></app-subnav-techniques>
  <app-subnav-video-library *ngIf="section == 'video-library'" (toggleMenu)="onToggleMenu()"></app-subnav-video-library>

</div>
</nav>
