import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResourcesService } from '../resources/resources.service';
import { UserSettingsService } from '../services/user-settings.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss'],
})
export class IntroComponent implements OnInit {
  isFetching = false;
  resource;

  public assetPath = environment.staticUrl;

  constructor(
    private resourcesService: ResourcesService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.isFetching = false;
  }
}
