<div class="container-fluid content-container mobile-footer-padding bg-white fh-nav">
    <div *ngIf="page=='terms'">
        <h4 class="content-title">Terms and Conditions</h4>
        <p>
          <strong
            >
        SELF-BREATHE for Chronic Breathlessness IRAS Project ID: 334979

      </strong>
    </p>
    <ul>
      <li>You have consented to take part in a research study to test if using  SELF-BREATHE improves people's breathlessness, you have been randomised to the SELF-BREATHE study arm.</li>
      <li>As you have been randomised to the SELF-BREATHE study arm you have been provided with a personal log in for  SELF-BREATHE.</li>
      <li>SELF-BREATHE is a  NEW RESEARCH INTERVENTION, therefore we need to test how well it works, your use of SELF-BREATHE will help us understand if SELF-BREATHE is helpful?</li>
      <li>You will have access to SELF-BREATHE for 12 weeks, we recommended that you log on to SELF-BREATHE at LEAST twice a week.</li>
      <li>SELF-BREATHE is a breathlessness self management programme, this means the information you enter is NOT monitored by a health professional, therefore, if you feel unwell or need medical advice  / support you will need to contact your GP or call 111.</li>
      <li>If you have any problems or questions about the information / exercises  described etc.. you can use the the SELF-BREATHE messaging service, we will aim to respond to your message within 72 hrs. Do not use this service to seek  medical advice - if you require medical advice / support you must call your GP or 111.</li>
    </ul>
        
    </div>
    <div *ngIf="page=='privacy'">
        <h4 class="content-title">Privacy Policy</h4>
        <p>
          <strong
            >Data Security and Privacy information for SELF-BREATHE
          </strong>
        </p>
       
          <p>
              Overview:
          </p>
          <p>
            The SELF-BREATHE website does not mandate that the provision of personal identifiable information, If you wish to, you can register an e-mail within SELF-BREATHE to enable easier password reset capability. This information will be stored in line with NHS digital data privacy and security standards.
          </p>
          <p>
              1. The SELF-BREATHE website will ask for personal information. This
              information will be stored in line with NHS Digital data privacy and
              security standards.
          </p>
          <p>
              2. SELF-BREATHE is developed and owned by King’s College Hospital and
              is a provider for the NHS and follows NHS Digital data privacy and
              security standards.
          </p>
          <p>
              3. The SELF-BREATHE website will collect anonymous information about
              the length of time spent logged in to SELF-BREATHE, number of online
              sessions completed, and number of online messages sent. This
              information will be used by research team at King’s College Hospital to
              improve the SELF-BREATHE website.
          </p>
          <p>
              4. If you experience a technical problem, Dr Charles Reilly at King’s
              College Hospital will respond to your concern.
          </p>
          <p>
              If you have any concerns or further questions, please contact: Dr
      Charles Reilly, e-mail:        <a href="mailto:charles.reilly@nhs.net">charles.reilly@nhs.net</a> Tel:
              020 3299 8062
          </p>
      </div>
      <div>
          <a href="/assets/docs/Data+Security+and+Privacy+document+for+SELF-BREATHE+study+V1+21072020.docx">Download data security and privacy document.</a>
      </div>
      <div>
          <br/>
      </div>
      <div>
          <em>
              The data security document has been reviewed, edited and signed off by
              King’s College Hospital London NHS Foundation Trust Data Protection
              Officer, it has been approved by the Research Ethics Committee and
              Heath Regulatory Authority, going forward, if / when we roll this out
              as a “clinical’ intervention then we would need to edit review.
          </em>
    </div>
  
</div>
