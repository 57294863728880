import { Component, OnDestroy, OnInit } from '@angular/core';
import { ResourcesService } from '../resources/resources.service';
import { UserSettingsService } from '../services/user-settings.service';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-patient-feedback',
  templateUrl: './patient-feedback.component.html',
  styleUrls: ['./patient-feedback.component.scss']
})
export class PatientFeedbackComponent implements OnInit {
  isFetching = false;
  resource;

  public assetPath = environment.staticUrl;

  constructor(
    private resourcesService: ResourcesService,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.isFetching = false;
    
    
    this.userSettingsService
      .saveSetting('patientFeedback', 1)
      .subscribe((responeData) => {});
  }
}
