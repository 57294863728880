import { AuthResponseData } from '../service/auth.service';
import { AuthService } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { EvaluationsService } from 'src/app/evaluations/evaluations.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isLoading = false;
  error = null;
  testSite = false;
  today = new Date();

  constructor(
    private authService: AuthService,
    private router: Router,
    private evaluationService: EvaluationsService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    // this.testSite = environment.testSite;
    const user = this.authService.user.getValue();
    this.isLoading = true;
    // try this..
    this.authService.user.subscribe((user) => {
      if (user && user.password_change) {
        // console.log(user);
        this.evaluationService.fetchAll().subscribe((responseData) => {
          if (this.evaluationService.checkToday(responseData).length < 1) {
            this.router.navigate(['/evaluations', 'evaluate-today']);
          } else {
            if (this.authService.redirect) {
              this.router.navigateByUrl(this.authService.redirect);
            } else {
              this.router.navigate(['/']);
            }
          }
        });
      } else {
        if (this.authService.redirect) {
          this.router.navigateByUrl(this.authService.redirect);
        } else {
          this.router.navigate(['/']);
        }
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 500);
    });
  }
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const username = form.value.username;
    const password = form.value.password;
    const rememberMe = form.value.rememberMe;
    let authObs: Observable<AuthResponseData>;

    this.isLoading = true;

    authObs = this.authService.login(username, password, rememberMe);

    authObs.subscribe(
      (resData) => {
        //console.log(resData);
        this.isLoading = false;
        // do they need to evaluate?
        this.router.navigate(['/']);
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );

    form.reset();
  }
}
