export const environment = {
  production: true,
  apiUrl: 'https://api.test.self-breathe.co.uk',
  staticUrl: 'https://static.test.self-breathe.co.uk',
  title: 'SELF-BREATHE',
  vapidKeys: {
    publicKey:
      'BLVm7PDZO36gFs9Htp0WrY7Rb9vmAycNFzKFdKYciNwbK5pyKP3j0dLZl7tHAiWLYHuFEwnTEdj_kS9pt4zKcbw',
    privateKey: 'Lg1JdtWU9Vz4V0yF41j63fy-Aw3tNiFngmriqD2kYc4',
  },
};
