<!--nav class="navbar navbar-expand-sm navbar-light navbar-subnav-alt d-md-none">
  <div class="container justify-content-center align-items-start">
    <button
      class="mr-auto btn-nav-menu btn-nav-menu-sm"
      type="button"
      [routerLink]="['/dashboard', 'last-week']"
    >
      <span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-left']"></fa-icon
      ></span>
      <span> Last Week</span>
    </button>
    <div class="indicators position-absolute">
      <span
        ><span class="icon-wrap mr-1"
          ><fa-icon
            [icon]="['fal', 'rectangle-portrait']"
            [routerLink]="['/dashboard', 'last-week']"
          ></fa-icon
        ></span>
        <span class="icon-wrap"
          ><fa-icon [icon]="['fas', 'rectangle-portrait']"></fa-icon
        ></span>
        <span class="icon-wrap ml-1"
          ><fa-icon
            [icon]="['fal', 'rectangle-portrait']"
            [routerLink]="['/dashboard', 'this-week']"
          ></fa-icon></span
      ></span>
    </div>
    <button
      class="ml-auto btn-nav-menu btn-nav-menu-sm"
      type="button"
      [routerLink]="['/dashboard', 'this-week']"
    >
      <span>This Week </span
      ><span class="icon-wrap"
        ><fa-icon [icon]="['fas', 'chevron-right']"></fa-icon
      ></span>
    </button>
  </div>
</nav>-->

<!-- intro video -->

<div
  *ngIf="!watchedVideo"
  class="video-overlay"
  style="
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.5);
  "
  (click)="dismissIntro()"
>
  <div
    class="video-container"
    style="
      max-width: 1920px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    "
    (click)="onClickVideo($event)"
  >
    <a
      style="
        position: absolute;
        z-index: 10;
        background-color: #fff;
        width: 32px;
        height: 32px;
        right: -16px;
        top: -16px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: #333;
        font-size: 1.5rem;
      "
      (click)="dismissIntro()"
    >
      <span class="icon-wrap icon-button"
        ><fa-icon [icon]="['fas', 'times']"></fa-icon
      ></span>
    </a>
    <video
      controls
      style="width: 100%; height: auto"
      src="{{ assetPath }}/Self Breathe - Intervention Promo.mp4"
      type="video/mp4"
      autoplay
    ></video>
  </div>
</div>

<div class="container mobile-footer-padding content-container pt-0 relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="row mb-3">
    <div class="col-12">
      <div>
        <div class="row" *ngIf="!gettingStartedCompleted">
          <div class="col">
            <div class="card card-widget">
              <div class="card-body relative">
                <h5 class="card-title">Getting Started</h5>
                <a (click)="onPrevTask()" class="float-left db-task-prev">
                  <span
                    class="icon-wrap icon-button"
                    aria-label="Previous db-task"
                    ><fa-icon [icon]="['far', 'chevron-left']"></fa-icon></span
                ></a>

                <a (click)="onNextTask()" class="float-right db-task-next">
                  <span class="icon-wrap icon-button" aria-label="Next db-task"
                    ><fa-icon [icon]="['far', 'chevron-right']"></fa-icon></span
                ></a>
                <swiper [(index)]="sliderIndex" [config]="swiperConfig">
                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img
                          src="/assets/img/video-still.jpg"
                          alt=""
                          class="img-fluid d-block d-sm-none b-2 mb-4"
                        />
                        <div class="db-task-num">1</div>
                        <div class="db-task-title">Watch Opening Video</div>
                        <div class="db-task-text">
                          Learn about the SELF-BREATHE programme and how it will
                          help your breathlessness.
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/welcome']"
                        >
                          Play Video
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img
                          src="/assets/img/video-still.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img
                          src="/assets/img/video-still-evaluate.jpg"
                          alt=""
                          class="img-fluid d-block d-sm-none b-2 mb-4"
                        />
                        <div class="db-task-num">2</div>
                        <div class="db-task-title">Complete Evaluation</div>
                        <div class="db-task-text">
                          Keep track of your breathlessness and how you are
                          feeling
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/evaluations', 'evaluate-today']"
                        >
                          Take Evaluation
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img
                          src="/assets/img/video-still-evaluate.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img
                          src="/assets/img/video-still-techniques.jpg"
                          alt=""
                          class="img-fluid d-block d-sm-none b-2 mb-4"
                        />
                        <div class="db-task-num">3</div>
                        <div class="db-task-title">
                          View techniques to help you
                        </div>
                        <div class="db-task-text">
                          Teaches you techniques specifically aimed to improve
                          your breathing and breathlessness
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/techniques']"
                        >
                          View techniques
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img
                          src="/assets/img/video-still-techniques.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img
                          src="/assets/img/video-still-plan.jpg"
                          alt=""
                          class="img-fluid d-block d-sm-none b-2 mb-4"
                        />
                        <div class="db-task-num">4</div>
                        <div class="db-task-title">Setup your action plan</div>
                        <div class="db-task-text">
                          Create your own breathlessness action plan, to help if
                          you have a breathlessness attack.
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/techniques', 'action-plan']"
                        >
                          Setup plan
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img
                          src="/assets/img/video-still-plan.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img
                          src="/assets/img/video-still-exercise.jpg"
                          alt=""
                          class="img-fluid d-block d-sm-none b-2 mb-4"
                        />
                        <div class="db-task-num">5</div>
                        <div class="db-task-title">Start getting active</div>
                        <div class="db-task-text">
                          Create your own personalised home exercise programme
                          to improve your activity levels and breathlessness
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/exercise']"
                        >
                          Get Active
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img
                          src="/assets/img/video-still-exercise.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img
                          src="/assets/img/video-still-goals.jpg"
                          alt=""
                          class="img-fluid d-block d-sm-none b-2 mb-4"
                        />
                        <div class="db-task-num">6</div>
                        <div class="db-task-title">Read about goal setting</div>
                        <div class="db-task-text">
                          Set yourself a personal goal / target to achieve while
                          using SELF-BREATHE
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/goals', 'about']"
                        >
                          Read about goals
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img
                          src="/assets/img/video-still-goals.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="db-task">
                    <div class="row mt-0">
                      <div class="col-md-6">
                        <img
                          src="/assets/img/video-still.jpg"
                          alt=""
                          class="img-fluid d-block d-sm-none b-2 mb-4"
                        />
                        <div class="db-task-num">7</div>
                        <div class="db-task-title">Watch Patient Feedback Video</div>
                        <div class="db-task-text">
                          Hear what patients say about SELF-BREATHE
                        </div>
                        <button
                          class="btn btn-outline-primary"
                          [routerLink]="['/patient-feedback']"
                        >
                          Play Video
                        </button>
                      </div>
                      <div class="col-md-6 d-none d-sm-block">
                        <img
                          src="/assets/img/video-still.jpg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </swiper>
                <div class="row mt-2 mb-1">
                  <div class="col col-xs text-center">
                    <a
                      href=""
                      class="icon-wrap mr-1 pointer"
                      tabindex="0"
                      (click)="$event.preventDefault(); onSetIndex(0)"
                      [ngClass]="{
                        'text-primary': sliderIndex == 0,
                        'text-menu-bg': sliderIndex != 0
                      }"
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></a>
                    <a
                      href=""
                      class="icon-wrap mr-1 pointer"
                      tabindex="0"
                      (click)="$event.preventDefault(); onSetIndex(1)"
                      [ngClass]="{
                        'text-primary': sliderIndex == 1,
                        'text-menu-bg': sliderIndex != 1
                      }"
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></a>
                    <a
                      href=""
                      class="icon-wrap mr-1 pointer"
                      tabindex="0"
                      (click)="$event.preventDefault(); onSetIndex(2)"
                      [ngClass]="{
                        'text-primary': sliderIndex == 2,
                        'text-menu-bg': sliderIndex != 2
                      }"
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></a>
                    <a
                      href=""
                      class="icon-wrap mr-1 pointer"
                      tabindex="0"
                      (click)="$event.preventDefault(); onSetIndex(3)"
                      [ngClass]="{
                        'text-primary': sliderIndex == 3,
                        'text-menu-bg': sliderIndex != 3
                      }"
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></a>
                    <a
                      href=""
                      class="icon-wrap mr-1 pointer"
                      tabindex="0"
                      (click)="$event.preventDefault(); onSetIndex(4)"
                      [ngClass]="{
                        'text-primary': sliderIndex == 4,
                        'text-menu-bg': sliderIndex != 4
                      }"
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></a>
                    <a
                      href=""
                      class="icon-wrap mr-1 pointer"
                      tabindex="0"
                      (click)="$event.preventDefault(); onSetIndex(5)"
                      [ngClass]="{
                        'text-primary': sliderIndex == 5,
                        'text-menu-bg': sliderIndex != 5
                      }"
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></a>
                    <a
                      href=""
                      class="icon-wrap mr-1 pointer"
                      tabindex="0"
                      (click)="$event.preventDefault(); onSetIndex(6)"
                      [ngClass]="{
                        'text-primary': sliderIndex == 6,
                        'text-menu-bg': sliderIndex != 6
                      }"
                      ><fa-icon [icon]="['fas', 'circle']"></fa-icon
                    ></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="gettingStartedCompleted && showLoginWidget">
          <div class="col">
            <div class="card card-widget">
              <div class="card-body">
                <h5 class="card-title">Welcome Back</h5>
                <div class="text-center">
                  <img
                    src="/assets/img/dashboard-people.png"
                    alt="People using self-breathe"
                    width="127"
                  />
                </div>

                <table
                  class="record-table db-table db-table-logins"
                  *ngIf="loginDays"
                >
                  <thead>
                    <tr>
                      <th *ngFor="let i of [0, 1, 2, 3, 4, 5, 6]">
                        {{ loginDays[i].day }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th *ngFor="let i of [0, 1, 2, 3, 4, 5, 6]">
                        <span
                          class="icon-wrap text-success"
                          *ngIf="loginDays[i].login"
                        >
                          <fa-icon [icon]="['fas', 'check']"></fa-icon>
                        </span>
                      </th>
                    </tr>
                  </tbody>
                </table>
                <p class="login-message">
                  You have signed in on {{ totalLogins(loginDays) }} days in the
                  last week
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row h-100"
          *ngIf="
            daysSinceEvaluation != 0 ||
            daysSinceWorkout != 0 ||
            goalsToReview.length != 0
          "
        >
          <div class="col">
            <div class="card card-widget">
              <div class="card-body">
                <h5 class="card-title">Reminders</h5>

                <ul class="task-list-alt">
                  <li *ngIf="daysSinceEvaluation != 0">
                    <a [routerLink]="['/evaluations', 'evaluate-today']">
                      <span class="task-icon">
                        <fa-icon [icon]="['fas', 'traffic-light']"></fa-icon>
                      </span>
                      <span class="task"
                        ><span class="task-title">Evaluation</span>
                        <ng-container *ngIf="latestEvaluation">
                          <span class="task-message">
                            You last took an evaluation
                            <strong>{{ daysSinceEvaluation }} days ago</strong>
                            and were in the
                            <strong
                              ><span *ngIf="latestEvaluation.zone === 'g'"
                                >Green</span
                              ><span *ngIf="latestEvaluation.zone === 'a'"
                                >Amber</span
                              ><span *ngIf="latestEvaluation.zone === 'r'"
                                >Red</span
                              >
                              Zone</strong
                            >.
                          </span>
                        </ng-container>
                      </span>
                      <span class="status">
                        <span class="icon-wrap">
                          <fa-icon
                            [icon]="['fas', 'arrow-alt-square-right']"
                          ></fa-icon
                        ></span> </span
                    ></a>
                  </li>
                  <li *ngIf="goalsToReview.length != 0">
                    <a [routerLink]="['/goals']">
                      <span class="task-icon">
                        <fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon>
                      </span>
                      <span class="task"
                        ><span class="task-title">Review Goals</span>
                        <span class="task-message"
                          >You currently have
                          <strong
                            >{{ goalsToReview.length }} goal<span
                              *ngIf="goalsToReview.length != 1"
                              >s</span
                            ></strong
                          >
                          to review.</span
                        >
                      </span>
                      <span class="status">
                        <span class="icon-wrap">
                          <fa-icon
                            [icon]="['fas', 'arrow-alt-square-right']"
                          ></fa-icon
                        ></span> </span
                    ></a>
                  </li>
                  <li *ngIf="daysSinceWorkout != 0">
                    <a [routerLink]="['/exercise']">
                      <span class="task-icon">
                        <fa-icon [icon]="['fas', 'walking']"></fa-icon>
                      </span>
                      <span class="task"
                        ><span class="task-title">Do a workout</span>
                        <span class="task-message"
                          >You have have not logged a workout on the
                          Self-Breathe Programme
                          <span *ngIf="daysSinceWorkout > 0">
                            for
                            <strong>{{ daysSinceWorkout }} days</strong>.</span
                          ></span
                        >
                      </span>
                      <span class="status">
                        <span class="icon-wrap">
                          <fa-icon
                            [icon]="['fas', 'arrow-alt-square-right']"
                          ></fa-icon
                        ></span> </span
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="row h-100">
          <div class="col-12 col-sm-6 col-xs-6 mb-3">
            <div class="card card-widget h-100">
              <div class="card-body pr-1 pl-1 position-relative">
                <span class="db-date" *ngIf="latestEvaluation">
                  <span class="day">{{
                    latestEvaluation.created | date : "EEE"
                  }}</span>
                  <span class="num">{{
                    latestEvaluation.created | date : "d"
                  }}</span>
                </span>

                <h5 class="card-title">Latest Evaluation</h5>

                <div *ngIf="!latestEvaluation">
                  <div class="widget-icons">
                    <span class="widget-icon"
                      ><fa-icon [icon]="['fas', 'traffic-light']"></fa-icon
                    ></span>
                    <span class="circle bg-grey">&nbsp;</span>
                  </div>

                  <button
                    class="btn btn-outline-primary"
                    [routerLink]="['/evaluations', 'evaluate-today']"
                  >
                    Take Today's
                  </button>
                </div>

                <div *ngIf="latestEvaluation">
                  <div class="widget-icons">
                    <span class="widget-icon"
                      ><fa-icon [icon]="['fas', 'traffic-light']"></fa-icon
                    ></span>
                    <span
                      class="circle bg-success"
                      [ngClass]="{
                        'bg-success': latestEvaluation.zone == 'g',
                        'bg-warning': latestEvaluation.zone == 'a',
                        'bg-danger': latestEvaluation.zone == 'r'
                      }"
                      >&nbsp;</span
                    >
                  </div>

                  <table class="record-table db-table" *ngIf="latestEvaluation">
                    <thead>
                      <tr>
                        <th>At <br />Time</th>
                        <th>Previous <br />24 Hrs</th>
                        <th>Distress <br />Level</th>
                        <th>General <br />Health</th>
                        <th>
                          Activity <br />
                          Levels
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td width="15%">
                          <div class="circle circle-o">
                            <span
                              [ngClass]="{
                                'text-success':
                                  '' + latestEvaluation.breathlessness_now <
                                  '4',
                                'text-warning':
                                  '' + latestEvaluation.breathlessness_now >
                                    '3' &&
                                  '' + latestEvaluation.breathlessness_now <
                                    '7',
                                'text-danger':
                                  '' + latestEvaluation.breathlessness_now > '6'
                              }"
                              >{{ latestEvaluation.breathlessness_now }}</span
                            >
                          </div>
                        </td>
                        <td width="15%">
                          <div class="circle circle-o">
                            <span
                              [ngClass]="{
                                'text-success':
                                  '' + latestEvaluation.breathlessness_24 < '4',
                                'text-warning':
                                  '' + latestEvaluation.breathlessness_24 >
                                    '3' &&
                                  '' + latestEvaluation.breathlessness_24 < '7',
                                'text-danger':
                                  '' + latestEvaluation.breathlessness_24 > '6'
                              }"
                              >{{ latestEvaluation.breathlessness_24 }}</span
                            >
                          </div>
                        </td>
                        <td width="15%">
                          <div class="circle circle-o">
                            <span
                              [ngClass]="{
                                'text-success':
                                  '' + latestEvaluation.distress < '4',
                                'text-warning':
                                  '' + latestEvaluation.distress > '3' &&
                                  '' + latestEvaluation.distress < '7',
                                'text-danger':
                                  '' + latestEvaluation.distress > '6'
                              }"
                              >{{ latestEvaluation.distress }}</span
                            >
                          </div>
                        </td>
                        <td width="15%">
                          <div class="circle circle-o">
                            <span
                              [ngClass]="{
                                'text-danger':
                                  '' + latestEvaluation.health < '40',
                                'text-warning':
                                  '' + latestEvaluation.health > '39' &&
                                  '' + latestEvaluation.health < '70',
                                'text-success':
                                  '' + latestEvaluation.health > '69'
                              }"
                              >{{ latestEvaluation.health }}</span
                            >
                          </div>
                        </td>

                        <td width="15%">
                          <div class="circle circle-o text-dark">
                            <span *ngIf="+latestEvaluation.since == 3">
                              <fa-icon
                                [icon]="['far', 'chevron-double-up']"
                                [fixedWidth]="true"
                              ></fa-icon>
                            </span>
                            <span *ngIf="+latestEvaluation.since == 2">
                              <fa-icon
                                [icon]="['far', 'chevron-up']"
                                [fixedWidth]="true"
                              ></fa-icon>
                            </span>

                            <span *ngIf="+latestEvaluation.since == 1">=</span
                            ><span *ngIf="+latestEvaluation.since == 4">
                              <fa-icon
                                [icon]="['far', 'chevron-down']"
                                [fixedWidth]="true"
                              ></fa-icon>
                            </span>
                            <span *ngIf="+latestEvaluation.since == 5">
                              <fa-icon
                                [icon]="['far', 'chevron-double-down']"
                                [fixedWidth]="true"
                              ></fa-icon>
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <!--div class="widget-updated">
                    {{ latestEvaluation.date_taken | date : "E d LLL" }}
                  </div-->
                  <!--div class="widget-updated mb-1">
                    Your activity levels are
                    {{ sinceLabels[latestEvaluation.since] }}
                  </div-->
                  <button
                    class="btn btn-outline-primary"
                    [routerLink]="['/evaluations', 'evaluate-today']"
                  >
                    <span *ngIf="evlauationToday">Retake</span>
                    <span *ngIf="!evlauationToday">Take Evaluation</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xs-6 mb-3">
            <div class="card card-widget h-100">
              <div class="card-body pr-1 pl-1">
                <span class="db-date" *ngIf="latestWorkout">
                  <span class="day">{{
                    latestWorkout.workout_date | date : "EEE"
                  }}</span>
                  <span class="num">{{
                    latestWorkout.workout_date | date : "d"
                  }}</span>
                </span>

                <h5 class="card-title">Latest Workout</h5>
                <div *ngIf="latestWorkout">
                  <div class="widget-icons">
                    <span class="widget-icon"
                      ><span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'walking']"></fa-icon></span
                    ></span>
                    <span class="circle bg-primary">{{
                      latestWorkout.rep_score
                    }}</span>
                  </div>
                  <!--div class="widget-updated">
                    {{ latestWorkout.workout_date | date : "E d LLL" }}
                  </div-->
                  <table
                    class="record-table db-table db-table-workouts"
                    *ngIf="latestWorkout"
                  >
                    <thead>
                      <tr>
                        <th>Sitting</th>
                        <th>Standing</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="db-table-cell">
                          {{ latestWorkout.sitting_reps }} reps
                        </td>
                        <td class="db-table-cell">
                          {{ latestWorkout.standing_reps }} reps
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngIf="!latestWorkout">
                  <div class="widget-icons">
                    <span class="widget-icon"
                      ><span class="icon-wrap"
                        ><fa-icon [icon]="['fas', 'walking']"></fa-icon></span
                    ></span>
                    <span class="circle bg-grey">&nbsp;</span>
                  </div>
                  <div class="widget-updated"></div>
                </div>

                <button
                  class="btn btn-outline-primary"
                  [routerLink]="['/exercise', 'create-workout']"
                >
                  Start New Workout
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-xs-12 mb-3">
            <div class="card card-widget h-100">
              <div class="card-body">
                <h5 class="card-title">My Goals</h5>
                <div class="widget-icons">
                  <span class="widget-icon"
                    ><span class="icon-wrap"
                      ><fa-icon
                        [icon]="['fas', 'bullseye-arrow']"
                      ></fa-icon></span
                  ></span>

                  <div class="goal-status-container mr-2">
                    <div class="goal-status">
                      <span class="icon-wrap mr-2"
                        ><fa-icon [icon]="['far', 'circle']"></fa-icon
                      ></span>
                      <span class="total">{{ goals | goalsByStatus : 1 }}</span>
                    </div>
                    <span class="goal-status-label">In Progress</span>
                  </div>

                  <div class="goal-status-container mr-2">
                    <div class="goal-status">
                      <span class="icon-wrap mr-2"
                        ><fa-icon [icon]="['far', 'check-circle']"></fa-icon
                      ></span>
                      <span class="total">{{ goals | goalsByStatus : 2 }}</span>
                    </div>
                    <span class="goal-status-label">Partially</span>
                  </div>

                  <div class="goal-status-container mr-2">
                    <div class="goal-status">
                      <span class="icon-wrap mr-2"
                        ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                      ></span>
                      <span class="total">{{ goals | goalsByStatus : 3 }}</span>
                    </div>
                    <span class="goal-status-label">Achieved</span>
                  </div>
                </div>
                <button
                  class="btn btn-outline-primary"
                  [routerLink]="['/goals', 'create']"
                >
                  Create New Goal
                </button>

                <hr />

                <h5 class="card-title">
                  <span class="icon-wrap"
                    ><i class="fas fa-bullseye-arrow"></i
                  ></span>
                  GOALS TO REVIEW
                </h5>
                <p *ngIf="goalsToReview.length == 0">No goals to review</p>
                <ul class="task-list">
                  <li *ngFor="let goal of goalsToReview">
                    <a [routerLink]="['/goals', 'view', goal.goal_id]">
                      <span class="date">
                        <span class="day">{{
                          goal.review | date : "EEE"
                        }}</span>
                        <span class="num">{{ goal.review | date : "d" }}</span>
                      </span>
                      <span class="task">{{ goal.goal }}</span>
                      <span class="status">
                        <span class="icon-wrap">
                          <fa-icon
                            [icon]="['far', 'circle']"
                            *ngIf="+goal.status === 1"
                          ></fa-icon>
                          <fa-icon
                            [icon]="['far', 'check-circle']"
                            *ngIf="+goal.status === 2"
                          ></fa-icon>
                          <fa-icon
                            [icon]="['fas', 'check-circle']"
                            *ngIf="+goal.status === 3"
                          ></fa-icon>
                        </span>
                      </span>
                    </a>
                  </li>
                </ul>

                <button
                  class="btn btn-outline-primary"
                  [routerLink]="['/goals', 'all']"
                >
                  View All Goals
                </button>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <div class="card card-widget h-100">
              <div class="card-body">
                <app-dashboard-week
                  [inputWeek]="'summary'"
                ></app-dashboard-week>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 d-none d-md-block"></div>
        </div>
        <div class="row" *ngIf="currentPlan">
          <div class="col-md-12">
            <div class="card card-widget">
              <div class="card-body">
                <h5 class="card-title">My Action Plan</h5>
                <div>
                  <p class="lead mt-3">
                    Breathlessness can be worsened by many factors such as the way you
        breathe, your thoughts and feelings and how active you are on a daily
        basis. Many people find having an action plan (or a list) of what helps
        them when they have a breathlessness attack very helpful.
                  </p>
                  <ul class="action-plan-list">
                    <li *ngFor="let plan of currentPlan; let i = index">
                      <span class="num">
                        {{ i + 1 }}
                      </span>
                      {{ plan.title }}
                      <span class="icon-wrap float-end" *ngIf="getLink(plan)">
                        <a [routerLink]="getLink(plan)" class="stretched-link">
                          <span class="icon-wrap">
                            <fa-icon
                              [icon]="['fas', 'arrow-alt-square-right']"
                            ></fa-icon>
                          </span>
                        </a>
                      </span>
                    </li>
                  </ul>
                  <button
                    class="btn btn-primary btn-block"
                    [routerLink]="['/techniques', 'action-plan']"
                  >
                    Edit action plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
