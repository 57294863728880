<div class="container mobile-footer-padding relative">
  <div class="img-container">
    <img
      src="/assets/img/video-still.jpg"
      alt="Image showing video still"
      class="img-fluid stage-nav-img resource-masthead-img fade-in d-block d-sm-none"
    />
    <img
      src="/assets/img/video-still.jpg"
      alt="Image showing video still"
      class="img-fluid stage-nav-img resource-masthead-img fade-in d-none d-sm-block drop-shadow rounded"
    />
  </div>
  <ul class="row content-list content-list-no-img mt-3">
    <li class="col-md-6">
      <a [routerLink]="['/intro']">
        <span class="content-label">Welcome to SELF-BREATHE, why and how to use SELF-BREATHE</span>
        <span class="icon-wrap">
          <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon>
        </span>
      </a>
    </li>
    <li class="col-md-6">
      <a [routerLink]="['/welcome']">
        <span class="content-label">Understanding chronic breathlessness</span>
        <span class="icon-wrap">
          <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon>
        </span>
      </a>
    </li>
    <li class="col-md-6">
      <a [routerLink]="['/patient-feedback']">
        <span class="content-label">Patients experiences of using SELF-BREATHE</span>
        <span class="icon-wrap">
          <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon>
        </span>
      </a>
    </li>
  </ul>
</div>
