import { Component, OnInit } from '@angular/core';
import {faCircle as fasCircle} from '@fortawesome/pro-solid-svg-icons';
import { EvaluationsService } from './evaluations.service';
import { Evaluation } from './evaluation.model';
import * as moment from 'moment';


@Component({
  selector: 'app-evaluate',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit {

  fasCircle = fasCircle;
  public evaluations: Evaluation[] = [];
  public latestEvaluation: Evaluation = null;
  public todaysEvaluation: Evaluation = null;
  public errors: string[];
  public days = [];
  public today = moment().format('YYYY-MM-DD');
  public monthAgo = moment().subtract(30, 'days').format('YYYY-MM-DD');

  constructor(private evaluationsService: EvaluationsService) { }

  ngOnInit(): void {
    this.evaluationsService.fetchAll().subscribe(
      evaluations => {
        this.evaluations = evaluations;
        if (this.evaluations.length > 0){
          this.latestEvaluation = this.evaluations[this.evaluations.length - 1];
        }

        // filter to the last 30 days only?
        const filteredEvaluations = [];
        this.evaluations.forEach((evaluation, index) => {
          if( moment(evaluation.date_taken) > moment().subtract(4, 'weeks').startOf('isoWeek')){
            filteredEvaluations.push(evaluation);
          }
          if(moment(evaluation.date_taken).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')){
            this.todaysEvaluation = evaluation;
          }

        });
        this.evaluations = filteredEvaluations;
      },
      error => {
        // this.errors.push(error.message);
      }
    );

    this.days.push(moment().subtract(4, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'));
    for (let i = 0; i < 34; i++){
      this.days.push(moment(this.days[i]).add(1, 'days').format('YYYY-MM-DD'));
    }
  }

}
