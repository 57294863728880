<app-subnav [title]="'Overview'" [section]="'exercise'"></app-subnav>


<div  *ngIf="showWarning" class=" content-wrap mobile-footer-padding relative text-page-container">
    <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>

    <h3 class="sm-title mt-3 mb-4" *ngIf="!showSafetyAdvice">Activity Level Self-Assessment</h3>
    <div *ngIf="!userSettings?.exerciseLevel && !showSafetyAdvice">
        
        <p>
            Research has shown that people with chronic breathlessness that do regular
            exercise at home become fitter and are less breathlessness. 
    <p>
        In order to direct you to the most suitable home exercise programme please answer the following question:
    </p>
    <div class="exercise-level-q">
        <h4>On a typical day, where do you spend most of your time?</h4>
    <p>
        <button class="btn btn-primary" (click)="onChooseLevel(1)"><svg class="svg-button-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M80,160a32,32,0,0,1,32-32h96a32,32,0,0,1,32,32v32h32V160a32,32,0,0,1,32-32h96a32,32,0,0,1,32,32v32h48V64a32,32,0,0,0-32-32H64A32,32,0,0,0,32,64V192H80Zm368,64H64A64,64,0,0,0,0,288V464a16,16,0,0,0,16,16H48a16,16,0,0,0,16-16V416H448v48a16,16,0,0,0,16,16h32a16,16,0,0,0,16-16V288A64,64,0,0,0,448,224Z"/></svg><br>In and around<br>your bed</button> &nbsp;&nbsp;&nbsp;or&nbsp;&nbsp;&nbsp; 
        <button class="btn btn-primary" (click)="onChooseLevel(2)"><svg class="svg-button-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M356.87,384.5a30.05,30.05,0,0,0,6.95-10.64l20.71-51.5,11.76-29.25c-.48-3-.81-6-1-9a63.54,63.54,0,0,1-58,.39c-31.26-15.74-44.05-54.16-28.52-85.64l6.73-13.65a127.24,127.24,0,0,1,66.4-61.67l7.52-3.07L263.52,8.92a35.32,35.32,0,0,0-46.92,0L4.8,196.42a14.18,14.18,0,0,0-1.23,20l18.79,21.28a14.2,14.2,0,0,0,20,1.25L64,219.87V384a32,32,0,0,0,32,32H325.44ZM224,272a16,16,0,0,1-16,16H144a16,16,0,0,1-16-16V208a16,16,0,0,1,16-16h64a16,16,0,0,1,16,16ZM528,96a48,48,0,1,0-48-48A48,48,0,0,0,528,96ZM393.55,385.74a61.78,61.78,0,0,1-14.18,21.49l-50,50.11a32,32,0,0,0,45.18,45.29l59.4-59.4a64.8,64.8,0,0,0,14.2-21.5l13.5-33.79c-55.29-60.29-38.7-41.79-47.4-53.69ZM622.42,245.06l-23.29-11.8-9.7-29.38c-14.7-44.61-55.7-75.81-102.19-75.9-36-.09-55.88,10.09-93.28,25.2a95.46,95.46,0,0,0-49.7,46.2L337.56,213a32.11,32.11,0,0,0,14.2,42.9,31.55,31.55,0,0,0,42.49-14.31L401,228a31.82,31.82,0,0,1,16.49-15.41l26.8-10.79-15.2,60.69A64.23,64.23,0,0,0,444,321.25l59.9,65.4a64.42,64.42,0,0,1,14.89,27.69L537,487.63a32,32,0,0,0,62.1-15.5l-22.2-89a63.81,63.81,0,0,0-14.9-27.7l-45.5-49.7,17.2-68.69,5.5,16.5a63.73,63.73,0,0,0,31.7,37l23.29,11.81a31.57,31.57,0,0,0,42.5-14.31A32.37,32.37,0,0,0,622.42,245.06Z"/></svg><br>Up and about<br>most of the day</button>
    </p>
        </div>
    </div>
    <div *ngIf="userSettings?.exerciseLevel && !showSafetyAdvice">
        <p *ngIf="userSettings?.exerciseLevel ==='1'">
            Based the information you provided we have put you on the home exercise programme for:<br>
            <strong>Bed and Chair-based exercises</strong>
            </p>
            <p *ngIf="userSettings?.exerciseLevel ==='2'">
                Based the information you provided we have put you on the home exercise programme for:<br>
                <strong>Chair and Standing-based exercises</strong>
            </p>
            <div class="exercise-level-q">
            <button class="btn btn-primary" (click)="showSafetyAdvice = true">Continue to Safety Advice</button>
        </div>
    </div>
    
      
    <div  *ngIf="userSettings?.exerciseLevel && showSafetyAdvice">
       
            <h3 class="sm-title mt-3 mb-4"> Safety Advice </h3>
        
        <ul>
            <li>
                If you have not done much physical activity for a while, you may want
                to get the all clear from a GP before starting.
            </li>
            <li>
                For the exercises that require a chair, chose one that is stable, solid
                and without wheels. You should be able to sit with feet flat on the
                floor and knees bent at right angles. Avoid chairs with arms, as this
                will restrict your movement.
            </li>
            <li>
                Wear loose, comfortable clothing and keep some water handy.
            </li>
            <li>
                Try to attempt these exercises at least twice a week; this will help to
                improve muscle strength, balance and co-ordination.
            </li>
            <li>
                Build up slowly and aim to increase the repetitions of each exercise
                over time.
            </li>
        </ul>
        <h3 class="sm-title mt-5 mb-4">Signs You Should Stop Exercising</h3>
       
        <p>
            It is important to listen to what your body is telling you.
        </p>
        <p>
            Immediately stop exercising and sit down with your feet elevated
            (if possible) if you experience any of the following symptoms:
        </p>
        <ul>
            <li>
                Experiencing Troubles Walking, Talking, or Thinking
            </li>
            <li>
                Nausea
            </li>
            <li>
                Dizziness
            </li>
            <li>
                Light headedness
            </li>
            <li>
                Irregular or Rapid Heart Rate
            </li>
            <li>
                Overall Weakness
            </li>
            <li>
                Extreme Shortness of Breath, Even After Taking Medications
            </li>
            <li>
                Severe Pressure or Pain in Your Arms
            </li>
        </ul>
        <p>
            If you unable to regain control of any of these symptoms then call 999.
        </p>
        <p>
            However, even if you do feel better, you should still report these symptoms
            to your GP.
        </p>
        <div class="exercise-level-q mt-5 mb-4">
        <p>
            <strong>
                Please press this button acknowledge that you have read and understand
                the advice given above.
            </strong>
            <br><br>
            <button class="btn btn-primary" (click)="onConfirmAdvice()">I Confirm</button>
        </p>
    </div>
    </div>
  </div>

<div class="container content-container" *ngIf="!showWarning"> 
    
    <div class="row">
      <div class="col col-xs">
          <button class="btn btn-primary btn-block" [routerLink]="['/exercise', 'workouts']"><span class="icon-wrap"><fa-icon [icon]="['far', 'calendar-alt']"></fa-icon></span> Workouts</button>
      </div>
      <div class="col col-xs">
          <button class="btn btn-primary btn-block" [routerLink]="['/exercise', 'add']"><span class="icon-wrap"><fa-icon [icon]="['far', 'calendar-edit']"></fa-icon></span> Add Entry</button>
      </div>
      <div class="col col-xs">
          <button class="btn btn-primary btn-block" [routerLink]="['/exercise', 'create-workout']"><span class="icon-wrap"><fa-icon [icon]="['far', 'calendar-edit']"></fa-icon></span> Create Workout</button>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col">

          <h4 class="content-title">
            <span class="aside aside-left">{{days[0] | date : 'd MMM'}} - {{today| date : 'd MMM'}}</span>
              30 Day Activity
              <span class="aside aside-right">{{workouts.length}} day<span *ngIf="workouts.length!=1">s</span> Active</span>
            </h4>

        <div class="card card-widget mb-2">
          <div class="card-body card-month">
            <table class="table table-activity">
                <thead>
                    <tr>
                        <th>M</th>
                        <th>T</th>
                        <th>W</th>
                        <th>T</th>
                        <th>F</th>
                        <th>S</th>
                        <th>S</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td *ngFor="let count of [0,1,2,3,4,5,6]">
                            <span *ngIf="days[count]>monthAgo" [ngClass]="{'circle bg-dark': (workouts | filterObjectArray : {'workout_date' : days[count]} : true).rep_data}">{{days[count] | date : 'd'}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td *ngFor="let count of [7,8,9,10,11,12,13]">
                            <span [ngClass]="{'circle bg-dark': (workouts | filterObjectArray : {'workout_date' : days[count]} : true).rep_data}">{{days[count] | date : 'd'}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td *ngFor="let count of [14,15,16,17,18,19,20]">
                            <span [ngClass]="{'circle bg-dark': (workouts | filterObjectArray : {'workout_date' : days[count]} : true).rep_data}">{{days[count] | date : 'd'}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td *ngFor="let count of [21,22,23,24,25,26,27]">
                            <span [ngClass]="{'circle bg-dark': (workouts | filterObjectArray : {'workout_date' : days[count]} : true).rep_data}">{{days[count] | date : 'd'}}</span>
                        </td>
                    </tr>
                    <tr>
                        <td *ngFor="let count of [28,29,30,31,32,33,34]">
                            <span *ngIf="days[count]<=today"  [ngClass]="{'circle bg-dark': (workouts | filterObjectArray : {'workout_date' : days[count]} : true).rep_data}">{{days[count] | date : 'd'}}</span>
                         </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
        <!--<button class="btn btn-lg btn-block btn-primary" [routerLink]="['/exercise', 'create-workout']">
          <span class="icon-wrap"><fa-icon [icon]="['fas', 'walking']"></fa-icon></span> Start New Workout</button>-->
      </div>
    </div>
    <div class="row mt-4" *ngIf="guides">
      <div class="col">

          <div class="row">
    <ng-container *ngFor="let resource of guides">

        <div class="col mobile-spacer" *ngIf="! ((userSettings.exerciseLevel ==='2'  && +resource.content_section_id === 19) || (userSettings.exerciseLevel ==='1'  && +resource.content_section_id === 21)) ">
          <h4 class="content-title">{{ resource.title }}</h4>
          <ul class="row content-list">
            <li *ngFor="let page of resource.pages" class="col-md-12">
              <a [routerLink]="[
              '/exercise',
              'guides',
              page.content_section_id,
              page.content_page_id
            ]">
              <img 
              src="{{page.image_filename | getThumbnail : 'medium-landscape'}}"
              alt=""
              class=""
              *ngIf="page.image_filename"
              height="55"
              />
              <img 
              src="https://via.placeholder.com/90x55"
              alt=""
              class="img-fluid"
              *ngIf="!page.image_filename"
              />
              <span class="content-label">{{page.title | uppercase}}</span>
              <span class="icon-wrap">
                <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon>
              </span>
            </a></li>
        </ul>
      </div>
    </ng-container>
    </div>
          

          
      </div>
    </div>
    <div class="row mt-4" *ngIf="resource">
      <div class="col">

          <h4 class="content-title">
              More Information
          </h4>
          <ul class="extra-list">
              <li *ngFor="let page of resource.pages;" style="min-height:80px;">
                  <a href="" class="text-white" [routerLink]="['/exercise', 'more-information', page.content_page_id]">
                  <img 
                  src="{{page.image_filename | getThumbnail : 'medium-landscape'}}"
                  alt=""
                  class="img-fluid"
                  *ngIf="page.image_filename"
                  />
                  <span>{{page.title | uppercase}}</span>
                </a>
              </li>
          </ul>
      </div>
    </div>
  </div>
