<div class="sidebar">
  <div class="logo">
    <img src="/assets/img/logo-dark.svg" alt="Self Breathe Logo" height="68" />
  </div>

  <nav class="sidebar-nav">
    <ul class="nav flex-column">
      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/dashboard']"
          routerLinkActive="active"
          (click)="onHideMore()"
        >
          <span class="nav-icon icon-wrap">
            <fa-icon [icon]="['fas', 'home']" [fixedWidth]="true"></fa-icon>
          </span>
          <span class="nav-link-title">Home</span>
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/evaluations']"
          routerLinkActive="active"
          (click)="onHideMore()"
        >
          <span class="nav-icon icon-wrap">
            <fa-icon
              [icon]="['fas', 'traffic-light']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
          <span class="nav-link-title">Evaluate</span>
        </a>

        <ul class="nav flex-column fade-in animate-250">
          
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/evaluations', 'all']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              All Evaluations
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/evaluations', 'evaluate-today']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Take Today's Evaluation
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/goals']" routerLinkActive="active"
        (click)="onHideMore()">
          <span class="nav-icon icon-wrap">
            <fa-icon
              [icon]="['fas', 'bullseye-arrow']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
          <span class="nav-link-title">Goals</span>
        </a>

        <ul class="nav flex-column fade-in animate-250">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/goals', 'about']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Set Your Goal
            </a>
          </li>
          <li class="nav-item">
            
            <a
              class="nav-link"
              [routerLink]="['/goals', 'all']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              My Goals
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/goals', 'create']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Create New Goal
            </a>
          </li>
          
        </ul>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/techniques']"
          routerLinkActive="active"
          (click)="onHideMore()"
        >
          <span class="nav-icon icon-wrap">
            <fa-icon [icon]="['fas', 'lungs']" [fixedWidth]="true"></fa-icon>
          </span>
          <span class="nav-link-title">Techniques</span>
        </a>

        <ul class="nav flex-column fade-in animate-250">
         
          <li class="nav-item" *ngFor="let page of techniques?.pages">
            <a
              class="nav-link"
              [routerLink]="['/techniques', page.content_page_id]"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              {{ page.title }}
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/techniques', 'action-plan']"
              routerLinkActive="active"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Action Plan
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          [routerLink]="['/exercise']"
          routerLinkActive="active"
          (click)="onHideMore()"
        >
          <span class="nav-icon icon-wrap">
            <fa-icon [icon]="['fas', 'walking']" [fixedWidth]="true"></fa-icon>
          </span>
          <span class="nav-link-title">Exercise</span>
        </a>

        <ul class="nav flex-column fade-in animate-250">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/exercise', 'more-information']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Key Information
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/exercise', 'workouts']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              All Workouts
            </a>
          </li>
          
          <li class="nav-item" *ngIf="userSettings?.exerciseLevel && userSettings?.exerciseLevel === '1'">
            <a
              class="nav-link"
              [routerLink]="['/exercise', 'guides', 19]"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Bed Based Exercise Guides
            </a>
          </li>
          <li class="nav-item" *ngIf="userSettings?.exerciseLevel">
            <a
              class="nav-link"
              [routerLink]="['/exercise', 'guides', 20]"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Sitting Exercise Guides
            </a>
          </li>
          <li class="nav-item" *ngIf="userSettings?.exerciseLevel && userSettings?.exerciseLevel === '2'">
            <a
              class="nav-link"
              [routerLink]="['/exercise', 'guides', 21]"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Standing Exercise Guides
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/exercise', 'add']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              My Exercise log
            </a>
          </li>
        </ul>
      </li>

      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/ask']" routerLinkActive="showMore ? null: active"
        (click)="onHideMore()">
          <span class="nav-icon icon-wrap">
            <fa-icon
              [icon]="['fas', 'user-md-chat']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
          <span class="nav-link-title">Ask a Professional</span>
        </a>
      </li>


      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/video-library']" routerLinkActive="'active'">
          <span class="nav-icon icon-wrap">
            <fa-icon
              [icon]="['fas', 'video']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
          <span class="nav-link-title">Video Library</span>
        </a>
      </li>



      <li class="nav-item">
        <a class="nav-link pointer" (click)="onToggleMore()" [ngClass]="{active: activeMore}">
          <span class="nav-icon icon-wrap">
            <fa-icon
              [icon]="['fas', 'ellipsis-h']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
          <span class="nav-link-title">More</span>
        </a>
        <ul class="nav flex-column fade-in animate-250" [style.display]="showMore ? 'block' : 'none'">
          
         
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/update-details']"
              routerLinkActive="active"
              (click)="onActiveMore();"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Update Your Details
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/change-password']"
              routerLinkActive="active"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Change Your Password
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link pointer" 
              (click)="logout()"
            >
              <span class="icon-wrap">
                <fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon>
              </span>
              Logout
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>
