<div class="fh bg-light pb-10 content-container">
  <div class="row" *ngIf="userData">
    <div class="col-md-12">
      <h3 class="mb-3 sm-title">Update your details</h3>
      <div class="alert alert-danger" *ngIf="error">
        <p>{{ error }}</p>
      </div>
      <div *ngIf="isLoading" class="text-center">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <form
        #authForm="ngForm"
        (ngSubmit)="onSubmit(authForm)"
        *ngIf="!updated"
        class="standard-form"
        name="update-details"
      >

       <p>If you would like to recieve email notifications from SELF-BREAHE you can enter your email address below.</p>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            type="email"
            id="email"
            class="form-control"
            [ngModel]="userData.email"
            name="email"
            placeholder="Email"
            required
            email
          />
        </div>
        <div class="form-group">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="notifications" name="notifications" [ngModel]="userData.notifications"
            #notifications="ngModel">
            <label class="form-check-label" for="notifications">Email notifications?</label>
          </div>
        </div>

       

        <div class="form-group">
          <button
            type="submit"
            [disabled]="!authForm.valid || isLoading"
            class="btn btn-primary btn-block"
          >
            Update
          </button>
        </div>
      </form>
      <p *ngIf="updated">
        If your email exists you will have been sent a link to reset your
        password.
      </p>
      <hr />
    </div>
  </div>
</div>
