import { UserDataInterface } from './userData.interface';
import { map, catchError } from 'rxjs/operators';
import { Subject, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  error = new Subject<string>();
  userData = new BehaviorSubject<UserDataInterface | null>(null);
  cachedUserData: UserDataInterface | null = null;

  constructor(private http: HttpClient) {}

  get() {
    if (this.cachedUserData === null) {
      return this.http
        .get<any>(environment.apiUrl + '/user_data', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const userData = responseData['_embedded'].user_data[0];
            this.cachedUserData = userData;
            this.userData.next(userData);
            return userData;
          }),
          catchError((errorRes) => {
            return of(errorRes);
          })
        );
    } else {
      // this.userData.next(userData);
      return of(this.userData.next(this.cachedUserData));
    }
  }
  reset() {
    this.cachedUserData = null;
  }
}

