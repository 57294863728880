import { forkJoin } from 'rxjs';
import { UserSettingsService } from './../../services/user-settings.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ExercisesService } from './../exercises.service';
import { Exercise } from '../exercise.model';
import { WorkoutsService } from './../workouts.service';

import * as moment from 'moment';

import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-exercise-add',
  templateUrl: './exercise-add.component.html',
  styleUrls: ['./exercise-add.component.scss'],
})
export class ExerciseAddComponent implements OnInit {
  exercises: Exercise[];
  step = 1;
  @ViewChild('form', { static: false }) workoutAddForm: NgForm;
  submitted = false;
  stepLabels = ['Zero', 'One', 'Two', 'Three', 'Four'];
  error: string;
  repData = {};
  userSettings;
  today: Date;
  environment = environment;

  constructor(
    private exercisesService: ExercisesService,
    private workoutsService: WorkoutsService,
    private router: Router,
    private userSettingsService: UserSettingsService
  ) {}

  ngOnInit(): void {
    this.today = new Date();
    forkJoin({
      exercises: this.exercisesService.fetchExercises(),
      userSettings: this.userSettingsService.fetchAll(),
    }).subscribe((responseData) => {
      this.exercises = responseData.exercises;
      this.userSettings = responseData.userSettings;
    });
  }

  getTypeReps(type: string) {
    let total = 0;
    if (this.exercises) {
      this.exercises.forEach((exercise) => {
        if (exercise.exercise_type == type || type === 'total') {
          if (this.repData[exercise.exercise_id]) {
            total += +this.repData[exercise.exercise_id];
          }
        }
      });
    }
    return total;
  }

  onSubmit() {
    this.submitted = true;
    const workout_date = moment(
      this.workoutAddForm.value.step1.workout_date
    ).format('YYYY-MM-DD');
    const repsData = {
      bed: { total: 0, data: {} },
      sitting: { total: 0, data: {} },
      standing: { total: 0, data: {} },
    };

    this.exercises.forEach((exercise) => {
      if (exercise.exercise_type === 'bed') {
        if (this.workoutAddForm.value.step2['reps_' + exercise.exercise_id]) {
          repsData.bed.total += +this.workoutAddForm.value.step2[
            'reps_' + exercise.exercise_id
          ];
          repsData.bed.data[
            exercise.exercise_id
          ] = this.workoutAddForm.value.step2['reps_' + exercise.exercise_id];
        }
      }
    });

    this.exercises.forEach((exercise) => {
      if (exercise.exercise_type === 'sit') {
        if (this.workoutAddForm.value.step3['reps_' + exercise.exercise_id]) {
          repsData.sitting.total += +this.workoutAddForm.value.step3[
            'reps_' + exercise.exercise_id
          ];
          repsData.sitting.data[
            exercise.exercise_id
          ] = this.workoutAddForm.value.step3['reps_' + exercise.exercise_id];
        }
      }
    });
    this.exercises.forEach((exercise) => {
      if (exercise.exercise_type === 'stand') {
        if (this.workoutAddForm.value.step4['reps_' + exercise.exercise_id]) {
          repsData.standing.total += +this.workoutAddForm.value.step4[
            'reps_' + exercise.exercise_id
          ];
          repsData.standing.data[
            exercise.exercise_id
          ] = this.workoutAddForm.value.step4['reps_' + exercise.exercise_id];
        }
      }
    });

    const repsDataFinal = {
      bed: repsData.bed.data,
      sitting: repsData.sitting.data,
      standing: repsData.standing.data,
    };

    console.log(repsData);
    console.log(
      +repsData.standing.total + +repsData.sitting.total + +repsData.bed.total
    );

    this.workoutsService
      .saveWorkout(
        workout_date,
        +repsData.bed.total,
        +repsData.sitting.total,
        +repsData.standing.total,
        JSON.stringify(repsDataFinal),
        +repsData.standing.total + +repsData.sitting.total + +repsData.bed.total
      )
      .subscribe(
        (responseData) => {
          this.userSettingsService
            .saveSetting('doWorkout', 1)
            .subscribe((responeData) => {});
          this.router.navigate(['/exercise', 'workouts']);
        },
        (error) => {
          this.error = error.message;
        }
      );

    this.workoutAddForm.reset();
  }

  onNextStep() {
    this.step++;
    if (this.step === 2 && this.userSettings.exerciseLevel === '2') {
      this.step++;
    }
    if (this.step === 4 && this.userSettings.exerciseLevel === '1') {
      this.step++;
    }
  }
}
