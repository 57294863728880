import { Message } from './../messages/message.model';
import { MessagesService } from './../messages/messages.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { UserSettingsService } from '../services/user-settings.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ask',
  templateUrl: './ask.component.html',
  styleUrls: ['./ask.component.scss'],
})
export class AskComponent implements OnInit {
  @ViewChild('form', { static: false }) messageForm: NgForm;

  public messages: Message[] = [];
  isFetching = false;
  error = null;
  private errorSub: Subscription;
  acknowledged:any = null;
  userSettings:any;

  constructor(private messagesService: MessagesService, private userSettingsService:UserSettingsService) {}

  ngOnInit(): void {
    this.errorSub = this.messagesService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });

    this.isFetching = true;
    this.messagesService.fetchMessages('e').subscribe(
      (messages) => {
        this.isFetching = false;
        this.messages = messages;
      },
      (error) => {
        this.isFetching = false;
        this.error = error.message;
      }
    );

    // get the user settings..
    this.userSettingsService.fetchAll().subscribe((responseData)=>{
      this.userSettings = responseData;
      if(this.userSettings['ateAcknowledged']==1){
        this.acknowledged = true;
      }
      else{
        this.acknowledged = false;
      }
      console.log(this.acknowledged);
    });
  }

  onConfirmAdvice() {
    this.userSettingsService
      .saveSetting('ateAcknowledged', 1)
      .subscribe((responseData) => {
        this.userSettings['ateAcknowledged'] = '1';
        this.acknowledged = true;
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Thank you for confirming',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
      });
  }

  onSubmit() {
    this.messagesService
      .createMessage(this.messageForm.value.message, 'e')
      .subscribe(
        (responseData) => {
          const message = new Message(
            responseData.body.message_id,
            responseData.body.sender_id,
            responseData.body.recipient_id,
            responseData.body.message,
            responseData.body.category,
            responseData.body.is_admin,
            responseData.body.created,
            responseData.body.modified
          );
          this.messages.push(message);
          this.messageForm.reset();
        },
        (error) => {
          this.error = error.message;
        }
      );
  }
}
