<app-subnav [title]="'Overview'" [section]="'techniques'"></app-subnav>
<div class="bg-menu-bg pb-5 d-lg-none d-xl-none d-md-none"></div>

<div class="container relative mobile-footer-padding">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>

  <div class="img-container" style="margin-top: 0px;" *ngIf="resource">
    <img
      [src]="resource?.image_filename | getThumbnail: 'wide'"
      alt="Image showing {{ resource?.title }}"
      class="img-fluid stage-nav-img resource-masthead-img fade-in d-block d-sm-none full"
      *ngIf="resource?.image_filename"
    />
    <img
      [src]="resource?.image_filename | getThumbnail: 'wide'"
      alt="Image showing {{ resource?.title }}"
      class="img-fluid stage-nav-img resource-masthead-img fade-in d-none d-sm-block drop-shadow rounded full"
      *ngIf="resource?.image_filename"
    />
  </div>

  

  <ul class="row content-list content-list-no-img mt-3">
    <li *ngFor="let page of pages;" class="col-md-6">
        <a [routerLink]="['/techniques',  page.content_page_id]">
        <!--img 
        src="{{page.image_filename | getThumbnail : 'medium-landscape'}}"
        alt="{{page.title}} image"
        *ngIf="page.image_filename"
        height="55"
        />
        <img 
        src="https://via.placeholder.com/90x55"
        alt="{{page.title}} image"
        class="img-fluid"
        *ngIf="!page.image_filename"
        /-->
        <span class="content-label">{{page.title | uppercase}}</span>
        <span class="icon-wrap">
          <fa-icon [icon]="['fas', 'arrow-alt-square-right']" *ngIf="!checkPageView(page.content_page_id)"></fa-icon>
          <fa-icon [icon]="['fas', 'check']" *ngIf="checkPageView(page.content_page_id)"></fa-icon>
        </span>
      </a></li>
</ul>

<ul class="content-list mt-2">
  <li class="important">
      <a [routerLink]="['/techniques',  'action-plan']">
      <span class="content-icon">
        <fa-icon [icon]="['fas', 'siren-on']"></fa-icon>
      </span>
      <span class="content-label">My Breathlessness Action Plan</span>
      <span class="icon-wrap">
        <fa-icon [icon]="['fas', 'arrow-alt-square-right']"></fa-icon>
      </span>
    </a></li>
</ul>

</div>

