import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import { Workout } from './workout.model';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WorkoutsService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/workouts/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const workout = new Workout(
            responseData.workout_id,
            responseData.user_id,
            responseData.workout_date,
            responseData.bed_reps,
            responseData.standing_reps,
            responseData.sitting_reps,
            responseData.rep_data,
            responseData.rep_score,
            responseData.created,
            responseData.modified
          );
          return workout;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchWorkouts(workout_date?: string) {
    let searchParams = new HttpParams();
    if (workout_date) {
      searchParams = searchParams.append(
        'workout_date',
        workout_date.toString()
      );
    }
    // make them order by date_taken asc
    searchParams = searchParams.append('sort', 'workout_date');
    searchParams = searchParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + '/workouts', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const workoutArray: Workout[] = [];
          responseData['_embedded'].workouts.forEach((workout) => {
            workoutArray.push(workout);
          });
          return workoutArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  saveWorkout(
    workout_date,
    bed_reps,
    sitting_reps,
    standing_reps,
    rep_data,
    rep_score
  ) {
    const today = new Date();
    const workoutData = {
      workout_date,
      bed_reps,
      sitting_reps,
      standing_reps,
      rep_data,
      rep_score,
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + '/workouts',
      workoutData,
      {
        observe: 'response',
      }
    );
  }

  updateWorkout(workout: Workout) {
    const workoutData = {
      workout_date: workout.workout_date,
      bed_reps: workout.bed_reps,
      standing_reps: workout.standing_reps,
      sitting_reps: workout.sitting_reps,
      rep_data: workout.rep_data,
      rep_score: workout.rep_score,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + '/workouts/' + workout.workout_id,
      workoutData,
      {
        observe: 'response',
      }
    );
  }

  delete(id) {
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/workouts/' + id
    );
  }
}
