<app-subnav [title]="'All Evaluations'" [section]="'evaluate'"></app-subnav>
<div class="container mobile-footer-padding content-container">
  <div class="row mt-0">
    <div class="col">
      <table class="record-table">
        <thead>
          <tr>
            <th></th>
            <th>At <br />Time</th>
            <th>Previous <br />24 Hrs</th>
            <th>Distress <br />Level</th>
            <th>General <br />Health</th>
            <th>
              Activity <br />
              Levels
            </th>
            <th>Your <br />Zone</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let evaluation of evaluations.slice().reverse()"
            [routerLink]="['/evaluations', 'view', evaluation.evaluation_id]"
          >
            <td class="td-date">
              <span class="num">{{ evaluation.date_taken | date : "d" }}</span>
              <span class="month">{{
                evaluation.date_taken | date : "MMM"
              }}</span>
            </td>
            <td width="15%">
              <div class="circle circle-o">
                <span
                  [ngClass]="{
                    'text-success': evaluation.breathlessness_now < '4',
                    'text-warning':
                      evaluation.breathlessness_now > '3' &&
                      evaluation.breathlessness_now < '7',
                    'text-danger': evaluation.breathlessness_now > '6'
                  }"
                  >{{ evaluation.breathlessness_now }}</span
                >
              </div>
            </td>
            <td width="15%">
              <div class="circle circle-o">
                <span
                  [ngClass]="{
                    'text-success': evaluation.breathlessness_24 < '4',
                    'text-warning':
                      evaluation.breathlessness_24 > '3' &&
                      evaluation.breathlessness_24 < '7',
                    'text-danger': evaluation.breathlessness_24 > '6'
                  }"
                  >{{ evaluation.breathlessness_24 }}</span
                >
              </div>
            </td>
            <td width="15%">
              <div class="circle circle-o">
                <span
                  [ngClass]="{
                    'text-success': evaluation.distress < '4',
                    'text-warning':
                      evaluation.distress > '3' && evaluation.distress < '7',
                    'text-danger': evaluation.distress > '6'
                  }"
                  >{{ evaluation.distress }}</span
                >
              </div>
            </td>
            <td width="15%">
              <div class="circle circle-o">
                <span
                  [ngClass]="{
                    'text-danger': evaluation.health < '40',
                    'text-warning':
                      evaluation.health > '39' && evaluation.health < '70',
                    'text-success': evaluation.health > '69'
                  }"
                  >{{ evaluation.health }}</span
                >
              </div>
            </td>

            <td width="15%">
              <div class="circle circle-o text-dark">
                <span *ngIf="+evaluation.since == 3">
                  <fa-icon
                    [icon]="['far', 'chevron-double-up']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span *ngIf="+evaluation.since ==2">
                  <fa-icon
                    [icon]="['far', 'chevron-up']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>

                <span *ngIf="+evaluation.since == 1">=</span
                ><span *ngIf="+evaluation.since == 4">
                  <fa-icon
                    [icon]="['far', 'chevron-down']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span *ngIf="+evaluation.since == 5">
                  <fa-icon
                    [icon]="['far', 'chevron-double-down']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </div>
            </td>
            <td width="15%">
              <div
                class="circle"
                [ngClass]="{
                  'bg-success': evaluation.zone == 'g',
                  'bg-warning': evaluation.zone == 'a',
                  'bg-danger': evaluation.zone == 'r'
                }"
              >
                &nbsp;
              </div>
            </td>
            <td><i class="far fa-ellipsis-v"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
