import { GoalUpdateComponent } from './goals/goal-update/goal-update.component';
import { ActionPlanComponent } from './action-plan/action-plan.component';
import { ExerciseWorkoutViewComponent } from './exercise/exercise-workout-view/exercise-workout-view.component';
import { ExerciseHowToPageComponent } from './exercise/exercise-how-to-page/exercise-how-to-page.component';
import { ExerciseHowToComponent } from './exercise/exercise-how-to/exercise-how-to.component';
import { ExerciseInfoPageComponent } from './exercise/exercise-info-page/exercise-info-page.component';
import { ExerciseInfoComponent } from './exercise/exercise-info/exercise-info.component';
import { AskComponent } from './ask/ask.component';
import { TechniquePageComponent } from './techniques/technique-page/technique-page.component';
import { ResourcePageComponent } from './resources/resource-page/resource-page.component';
import { ResourceIndexComponent } from './resources/resource-index/resource-index.component';
import { TechniquesComponent } from './techniques/techniques.component';
import { LegalComponent } from './legal/legal.component';
import { UpdateDetailsComponent } from './auth/update-details/update-details.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgottenPasswordComponent } from './auth/forgotten-password/forgotten-password.component';
// import { RegisterComponent } from './auth/register/register.component';
import { GoalsViewComponent } from './goals/goals-view/goals-view.component';
import { GoalsAllComponent } from './goals/goals-all/goals-all.component';
import { GoalsAboutComponent } from './goals/goals-about/goals-about.component';
import { EvaluationsViewComponent } from './evaluations/evaluations-view/evaluations-view.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { EvaluateTodayComponent } from './evaluations/evaluate-today/evaluate-today.component';
import { GoalsCreateComponent } from './goals/goals-create/goals-create.component';
import { EvaluationComponent } from './evaluations/evaluation.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { GoalsComponent } from './goals/goals.component';
import { ResourcesComponent } from './resources/resources.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardWeekComponent } from './dashboard/dashboard-week/dashboard-week.component';
import { EvaluationsAllComponent } from './evaluations/evaluations-all/evaluations-all.component';
import { ExerciseAddComponent } from './exercise/exercise-add/exercise-add.component';
import { ExerciseWorkoutsComponent } from './exercise/exercise-workouts/exercise-workouts.component';
import { CreateWorkoutComponent } from './exercise/create-workout/create-workout.component';

import { AuthGuard } from './auth/guard/auth.guard';
import { PatientFeedbackComponent } from './patient-feedback/patient-feedback.component';
import { VideoLibraryComponent } from './video-library/video-library.component';
import { IntroComponent } from './intro/intro.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      pageTitle: 'Login',
    },
  },
  /*
  {
    path: 'register',
    component: RegisterComponent,
    data: { hideFooterNav: true, hideTopNav: true, hideSiebar: true, bgDark: true, pageTitle: 'Register' },
  },*/
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      pageTitle: 'Forgotten Password',
    },
  },
  {
    path: 'reset-password/:id/:hash',
    component: ResetPasswordComponent,
    data: {
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      pageTitle: 'Rest Password',
    },
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      noContainer: false,
      bgDark: false,
      pageTitle: 'More',
    },
  },
  {
    path: 'update-details',
    component: UpdateDetailsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      noContainer: false,
      bgDark: false,
      pageTitle: 'More',
    },
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: { hideSiebar: true, pageTitle: 'Home', home: true },
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Welcome',
      hideSiebar: false,
      pageTitle: 'Video Library',
    },
  },
  {
    path: 'patient-feedback',
    component: PatientFeedbackComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Welcome',
      hideSiebar: false,
      pageTitle: 'Video Library',
    },
  },
  {
    path: 'intro',
    component: IntroComponent,
    canActivate: [AuthGuard],
    data: { animation: 'Intro', hideSiebar: false, pageTitle: 'Video Library' },
  },
  {
    path: 'video-library',
    component: VideoLibraryComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Welcome',
      hideSiebar: false,
      pageTitle: 'Video Library',
    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Dashboard',
      hideSiebar: false,
      pageTitle: 'Home',
      home: true,
    },
  },
  {
    path: 'dashboard/last-week',
    component: DashboardWeekComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: null, week: 'last', animation: 'DashboardLastWeek' },
  },
  {
    path: 'dashboard/this-week',
    component: DashboardWeekComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: null, week: 'this', animation: 'DashboardThisWeek' },
  },
  {
    path: 'evaluations',
    component: EvaluationComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Evaluate', animation: 'Evaluation' },
  },
  {
    path: 'evaluations/evaluate-today',
    component: EvaluateTodayComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Evaluate',
      hideFooterNav: false,
      animation: 'EvaluationToday',
      subTitle: 'Today',
    },
  },
  {
    path: 'evaluations/view/:id',
    component: EvaluationsViewComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Evaluate',
      hideFooterNav: false,
      animation: 'EvaluationView',
      subTitle: 'View',
    },
  },
  {
    path: 'evaluations/all',
    component: EvaluationsAllComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Evaluate',
      animation: 'EvaluationAll',
      subTitle: 'All',
    },
  },
  {
    path: 'techniques',
    component: TechniquesComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Techniques', animation: 'Techniques' },
  },
  {
    path: 'techniques/action-plan',
    component: ActionPlanComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Techniques',
      hideFooterNav: false,
      animation: 'ActionPlan',
      subTitle: 'Action Plan',
    },
  },
  {
    path: 'techniques/:pageId',
    component: TechniquePageComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Techniques',
      hideFooterNav: false,
      animation: 'TechniquePage',
    },
  },
  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Resources', animation: 'Resources' },
  },
  {
    path: 'resources/:id',
    component: ResourceIndexComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Resources',
      hideFooterNav: false,
      animation: 'ResourcesIndex',
    },
  },
  {
    path: 'resources/:resourceId/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Resources',
      hideFooterNav: false,
      animation: 'ResourcesPage',
    },
  },
  {
    path: 'exercise',
    component: ExerciseComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Exercise', animation: 'Exercise' },
  },
  {
    path: 'exercise/add',
    component: ExerciseAddComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Exercise', animation: 'ExerciseAdd', subTitle: 'Add' },
  },
  {
    path: 'exercise/workouts',
    component: ExerciseWorkoutsComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Exercise',
      animation: 'ExerciseWorkouts',
      subTitle: 'Workouts',
    },
  },
  {
    path: 'exercise/create-workout',
    component: CreateWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Exercise',
      animation: 'ExerciseCreateWorkout',
      subTitle: 'Create Workout',
    },
  },

  {
    path: 'exercise/workouts/view/:id',
    component: ExerciseWorkoutViewComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Exercise',
      hideFooterNav: false,
      animation: 'ExerciseWorkoutView',
      subTitle: 'View Workout',
    },
  },
  {
    path: 'exercise/more-information',
    component: ExerciseInfoComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Exercise',
      animation: 'ExerciseInfoIndex',
      subTitle: 'More Information',
    },
  },
  {
    path: 'exercise/more-information/:pageId',
    component: ExerciseInfoPageComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Exercise',
      hideFooterNav: false,
      animation: 'ExerciseInfoPage',
      subTitle: 'More Information',
    },
  },
  {
    path: 'exercise/guides',
    component: ExerciseHowToComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Exercise',
      animation: 'ExerciseHowTo',
      subTitle: 'How to Exercise',
    },
  },
  {
    path: 'exercise/guides/:resourceId',
    component: ExerciseHowToComponent,
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      pageTitle: 'Exercise',
      hideFooterNav: false,
      animation: 'ExerciseHowToPage',
      subTitle: 'How to Exercise',
    },
  },
  {
    path: 'exercise/guides/:resourceId/:pageId',
    component: ExerciseHowToPageComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Exercise',
      hideFooterNav: false,
      animation: 'ExerciseHowToPage',
      subTitle: 'How to Exercise',
    },
  },
  {
    path: 'ask',
    component: AskComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Ask', animation: 'Ask' },
  },
  // goals
  {
    path: 'goals',
    component: GoalsComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Goals', animation: 'Goals' },
  },
  {
    path: 'goals/about',
    component: GoalsAboutComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Goals', animation: 'GoalsAbout', subTitle: 'About' },
  },
  {
    path: 'goals/all',
    component: GoalsAllComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Goals', animation: 'GoalsAll', subTitle: 'All' },
  },
  {
    path: 'goals/create',
    component: GoalsCreateComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Goals', animation: 'GoalsCreate', subTitle: 'Create' },
  },

  {
    path: 'goals/view/:id',
    component: GoalsViewComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Goals', animation: 'GoalsView', subTitle: 'View' },
  },
  {
    path: 'goals/update/:id',
    component: GoalUpdateComponent,
    canActivate: [AuthGuard],
    data: { pageTitle: 'Goals', animation: 'GoalsUpdate', subTitle: 'Update' },
  },
  {
    path: 'terms-and-conditions',
    component: LegalComponent,
    data: { pageTitle: 'Terms', page: 'terms', animation: 'Terms' },
  },
  {
    path: 'privacy-policy',
    component: LegalComponent,
    data: { pageTitle: 'Privacy', page: 'privacy', animation: 'Privacy' },
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
    data: {
      pageTitle: 'Page Not Found',
      animation: 'PageNotFound',
      subTitle: 'Not found',
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: false,
    },
  },
  { path: '**', redirectTo: '/page-not-found' },
  //  {path: 'login', component: DashboardComponent, data: {showFooterNav: false}},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
