<app-subnav [title]="'Add Entry to Workouts'" [section]="'exercise'"></app-subnav>
<div class="container mobile-footer-padding content-container">

    <div class="row mt-0 mb-4">
      <div class="col col-xs text-center" *ngIf="userSettings?.exerciseLevel">
          <span class="icon-wrap mr-1" [ngClass]="{'text-primary':step===1, 'text-menu-bg':step!=1}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
          <span class="icon-wrap mr-1" [ngClass]="{'text-primary':(step===2 && userSettings?.exerciseLevel ==='1') || (step===3 && userSettings?.exerciseLevel ==='2'), 'text-menu-bg':!((step===2 && userSettings?.exerciseLevel ==='1') || (step===3 && userSettings?.exerciseLevel ==='2'))}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
          <span class="icon-wrap mr-1" [ngClass]="{'text-primary':(step===3 && userSettings?.exerciseLevel ==='1') || (step===4 && userSettings?.exerciseLevel ==='2'), 'text-menu-bg':!((step===3 && userSettings?.exerciseLevel ==='1') || (step===4 && userSettings?.exerciseLevel ==='2'))}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
          <span class="icon-wrap mr-1" [ngClass]="{'text-primary':step===5, 'text-menu-bg':step!=5}"><fa-icon [icon]="['fas', 'circle']"></fa-icon></span>
      </div>
    </div>
    <div class="row mt-0">
      <div class="col">

        <form class="card-form" (ngSubmit)="onSubmit()" #form="ngForm">

            <div ngModelGroup="step1" #step1Group="ngModelGroup"  [hidden]="step!=1">

            <h4 class="content-title">
                1. DATE OF WORKOUT
                </h4>
                <div class="card">
                    <div class="card-body">

                        <div class="form-group">
                            <input
                            class="form-control"
                            name="workout_date"
                            #review="bsDatepicker"
                            bsDatepicker
                            placeholder="DD/MM/YYY"
                            autocomplete="off"
                            [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-sb', showWeekNumbers: false, customTodayClass: 'is-today' }"
                            [maxDate]="today"
                            ngModel
                            required
                            >
                        </div>


                    </div>
                </div>

            </div>



            <div ngModelGroup="step2" #step2Group="ngModelGroup" [hidden]="step!=2">
                <h4 class="content-title">
                  2. BED BASED EXERCISES
                </h4>
    
                <div class="card" *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'bed'})">
                    <div class="card-body">
                          <div class="row mt-0">
                              <div class="col col-7 text-left">
                                  <label>{{exercise.name}}</label>
                                  <div><img src="{{environment.staticUrl}}/{{exercise.image_url}}" alt="{{exercise.name}}" width="100%" *ngIf="exercise.image_url"></div>
                              </div>
                              <div class="col col-3 text-right text-middle">
                                  <input
                                    type="text" pattern="\d*" maxlength="2"
                                    class="form-control text-center"
                                    name="reps_{{exercise.exercise_id}}"
                                    id="reps_{{exercise.exercise_id}}"
                                    [(ngModel)]="repData[exercise.exercise_id]"
                                    placeholder="0"
                                  >
                              </div>
                              <div class="col col-2 text-left text-middle">
                                  <label class="ms-1">Reps</label>
                              </div>
                          </div>
                    </div>
                </div>
                </div>

            <div ngModelGroup="step3" #step3Group="ngModelGroup" [hidden]="step!=3">
            <h4 class="content-title">
                <span *ngIf="userSettings?.exerciseLevel == '2'">2.</span>  <span *ngIf="userSettings?.exerciseLevel == '1'">3.</span>  SITTING EXERCISES
            </h4>

            <div class="card" *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'sit'})">
                <div class="card-body">
                      <div class="row mt-0">
                          <div class="col col-7 text-left ">
                              <label >{{exercise.name}}</label>
                              <div><img src="{{environment.staticUrl}}/{{exercise.image_url}}" alt="{{exercise.name}}" width="100%" *ngIf="exercise.image_url"></div>
                          </div>
                          <div class="col col-3 text-right text-middle">
                              <input
                                type="text" pattern="\d*" maxlength="2"
                                class="form-control text-center"
                                name="reps_{{exercise.exercise_id}}"
                                id="reps_{{exercise.exercise_id}}"
                                [(ngModel)]="repData[exercise.exercise_id]"
                                placeholder="0"
                              >
                          </div>
                          <div class="col col-2 text-left text-middle">
                              <label class="ml-1">Reps</label>
                          </div>
                      </div>
                </div>
            </div>
            </div>


            <div ngModelGroup="step4" #step4Group="ngModelGroup"  [hidden]="step!=4">

            <h4 class="content-title">
                3. STANDING EXERCISES
              </h4>

              <div class="card" *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'stand'})">
                  <div class="card-body">
                        <div class="row mt-0">
                            <div class="col col-7 text-left">
                                <label>{{exercise.name}}</label>
                                <div><img src="{{environment.staticUrl}}/{{exercise.image_url}}" alt="{{exercise.name}}" width="100%" *ngIf="exercise.image_url"></div>
                            </div>
                            <div class="col col-3 text-right text-middle">
                                <input
                                type="text" pattern="\d*" maxlength="2"
                                    class="form-control text-center"
                                    name="reps_{{exercise.exercise_id}}"
                                    id="reps_{{exercise.exercise_id}}"
                                    [(ngModel)]="repData[exercise.exercise_id]"
                                    placeholder="0"
                                >
                            </div>
                            <div class="col col-2 text-left text-middle">
                                <label class="ml-1">Reps</label>
                            </div>
                        </div>

                  </div>
              </div>
              </div>

              <div ngModelGroup="step5" #step5Group="ngModelGroup"  [hidden]="step!=5">

                <h4 class="content-title">
                    4. PREVIEW
                  </h4>
                  <div class="card card-widget mt-4" *ngIf="userSettings?.exerciseLevel == '1'" >
                    <div class="card-body">
                        <div class="row mt-0">
                            <div class="col text-left"><h5 class="card-title card-title-u">Bed based <span class="float-right">{{getTypeReps('bed')}}  Reps</span></h5></div>
                        </div>
                        <div class="row text-left mt-0">
                            <div class="col small">
                               <span *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'bed'})">
                                <p class=" mb-1" *ngIf="repData[exercise.exercise_id]">{{exercise.name}} <span class="float-right">{{repData[exercise.exercise_id]}}</span></p>
                              </span>
  
                            </div>
                        </div>
  
                    </div>
                  </div>

                <div class="card card-widget mt-4">
                  <div class="card-body">
                      <div class="row mt-0">
                          <div class="col text-left"><h5 class="card-title card-title-u">Sitting <span class="float-right">{{getTypeReps('sit')}}  Reps</span></h5></div>
                      </div>
                      <div class="row text-left mt-0">
                          <div class="col small">
                             <span *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'sit'})">
                              <p class=" mb-1" *ngIf="repData[exercise.exercise_id]">{{exercise.name}} <span class="float-right">{{repData[exercise.exercise_id]}}</span></p>
                            </span>

                          </div>
                      </div>

                  </div>
                </div>

                <div class="card card-widget mt-4" *ngIf="userSettings?.exerciseLevel == '2'" >
                  <div class="card-body">
                      <div class="row mt-0">
                          <div class="col text-left"><h5 class="card-title card-title-u">Standing <span class="float-right">{{getTypeReps('stand')}}  Reps</span></h5></div>
                      </div>
                      <div class="row text-left mt-0">
                          <div class="col small">
                            <span *ngFor="let exercise of  (exercises | filterObjectArray : {exercise_type :'stand'})">
                             <p class=" mb-1" *ngIf="repData[exercise.exercise_id]">{{exercise.name}} <span class="float-right">{{repData[exercise.exercise_id]}}</span></p>
                           </span>
                          </div>
                      </div>

                  </div>
                </div>

                <div class="jumbotron text-center mt-4">
                  <h5>TOTAL REPS SCORE</h5>
                  <span class="circle bg-dark">{{getTypeReps('total')}}</span>
              </div>


              </div>



        <button class="btn btn-primary btn-block mt-2 btn-lg"
            *ngIf="step < 5"
            [disabled]="(step==1 && !step1Group.valid) || (step==2 && !step2Group.valid) || (step==3 && !step3Group.valid)  || (step==4&& !step4Group.valid)  || (step==5 && !form.valid)"
            type="button"
            (click)="onNextStep()"
        >
        Next
        </button>
        <button class="btn btn-primary btn-block mt-2 btn-lg"
        *ngIf="step == 5"
        [disabled]="!form.valid"
        >
        Save
        </button>

        <div class="alert alert-danger" *ngIf="error">An error occured : {{error}}</div>

          </form>
      </div>
    </div>



  </div>
