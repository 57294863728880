import { UserSettingsService } from './../services/user-settings.service';
import { Title } from '@angular/platform-browser';
import { ResourcesService } from './../resources/resources.service';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { PageViewService } from '../services/page-view.service';

@Component({
  selector: 'app-techniques',
  templateUrl: './techniques.component.html',
  styleUrls: ['./techniques.component.scss'],
})
export class TechniquesComponent implements OnInit, OnDestroy {
  public resource;
  isFetching = false;
  error = null;
  private errorSub: Subscription;
  environment = environment;
  pages;
  resourceId = 22;
  pagesViewed: any = [];

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private titleService: Title,
    private userSettingsService: UserSettingsService,
    private pageViewService: PageViewService
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    this.resourcesService
      .fetchResource(this.resourceId)
      .subscribe((responseData) => {
        this.isFetching = false;
        this.pages = responseData.pages;
        this.resource = responseData.resource;
        // set custom HTML title
        this.titleService.setTitle(
          environment.title + ' | Techniques | ' + this.resource.title
        );
      });

    this.pageViewService.fetchAll().subscribe((responseData: any) => {
      this.pagesViewed = responseData;
    });
  }

  checkPageView(pageId: number) {
    let viewed = false;
    this.pagesViewed.forEach((pageView) => {
      if (pageView.page_id == pageId) {
        viewed = true;
      }
    });
    return viewed;
  }

  ngOnDestroy(): void {
    this.userSettingsService
      .saveSetting('viewTechniques', 1)
      .subscribe((responeData) => {});
  }
}
