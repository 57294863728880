import { environment } from 'src/environments/environment';
import { UserService } from '../service/user.service';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AuthService } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UserDataInterface } from '../service/userData.interface';
import * as moment from 'moment';

@Component({
  selector: 'app-update-details',
  templateUrl: './update-details.component.html',
  styleUrls: ['./update-details.component.scss'],
})
export class UpdateDetailsComponent implements OnInit {
  isLoading = false;
  error: string = '';
  updated = false;
  userData!: UserDataInterface;
  environment = environment;
  submitted = false;
  bsDate = new Date();
  maxDate = moment().add(1, 'year').toDate();

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        if(!this.userData.email){
          this.userData.email = '';
        }
      }
    });
  }

  onSubmit(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const notifications = form.value.notifications;

    let authObs: Observable<any>;

    this.isLoading = true;

    authObs = this.authService.updateDetails(
      email,
      notifications
    );

    authObs.subscribe(
      (resData) => {
        this.isLoading = false;
        this.updated = true;
        this.router.navigate(['/dashboard']);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Details updated',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );
  }
}
