<nav class="navbar navbar-expand-lg navbar-dark bg-dark navbar-main" [ngClass]="{'navbar-tall':tallNav}">
  <div class="container justify-content-center align-items-start relative-md">

    <button (click)="onBack()" class="back-link btn btn-outline-bg-menu" *ngIf="!router.isActive('/dashboard', true) && !router.isActive('/register', true)">
      <fa-icon [icon]="['far', 'chevron-left']"></fa-icon>
      <span class="sr-only">Back</span>
    </button>

    <a class="navbar-brand mx-auto position-absolute"
      > 
      <h1>
        <img  [ngClass]="{'d-lg-none' : home }"
          class="d-block mt-2"
          *ngIf="!pageTitle || home"
          src="/assets/img/logo-white.svg"
          alt="Self Breathe Logo"
        />
        <span *ngIf="pageTitle" [ngClass]="{'d-none d-lg-block' : home }">{{ pageTitle }}</span>
    </h1>
    </a>

    <div class="collapse navbar-collapse justify-content-end navbar-desktop">
      <ul class="navbar-nav">
        <li class="nav-item"
        routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/dashboard']">
              <span class="icon-wrap"><fa-icon [icon]="['fas', 'home']"></fa-icon></span>
              <span class="nav-label">Home</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/evaluations']">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'traffic-light']"></fa-icon></span>
            <span class="nav-label">Evaluate</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/techniques']">
            <span class="icon-wrap"
              ><fa-icon [icon]="['fas', 'lungs']"></fa-icon></span>
            <span class="nav-label">Techniques</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/exercise']">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'walking']"></fa-icon></span>
            <span class="nav-label">Exercise</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/goals']">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon></span>
            <span class="nav-label">Goals</span>
          </a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/resources']">
            <span class="icon-wrap"><fa-icon [icon]="['fas', 'books']"></fa-icon></span>
            <span class="nav-label">Resources</span>
          </a>
        </li>
      </ul>
    </div>
    
    <div class="ml-auto btn-group" dropdown>
      <button
        class="btn-nav-menu"
        id="user-dropdown-btn"
        dropdownToggle
        type="button"
      >
        <fa-icon [icon]="['fas', 'ellipsis-h']"></fa-icon>
      </button>
      <ul
        id="dropdown-user"
        *dropdownMenu
        class="dropdown-menu dropdown-menu-right bg-dark"
        role="menu"
        aria-labelledby="user-dropdown-btn"
      >

      <li role="menuitem">
        <a class="dropdown-item" [routerLink]="['/ask']"
          >Ask a professional</a
        >
      </li>
      <li role="menuitem">
        <a class="dropdown-item" [routerLink]="['/video-library']"
          >Video Library</a
        >
      </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/update-details']"
            >Update Your Details</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" [routerLink]="['/change-password']"
            >Change Your Password</a
          >
        </li>
        <li role="menuitem">
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
