import { FilterObjectArray } from './shared/filter-object-array.pipe';
import { ZoneToColour } from './shared/zone-to-colour.pipe';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CookieService } from 'ngx-cookie-service';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false;
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faBooks as fasBooks,
  faBullseyeArrow as fasBullseyeArrow,
  faCheckCircle as fasCheckCircle,
  faRectanglePortrait as fasRectanglePortrait,
  faWalking as fasWalking,
  faTrafficLight as fasTrafficLight,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faSlidersH as fasSlidersH,
  faCircle as fasCircle,
  faTimes as fasTimes,
  faCheckSquare as fasCheckSquare,
  faHome as fasHome,
  faCheck as fasCheck,
  faEllipsisH as fasEllipsisH,
  faBars as fasBars,
  faLungs as fasLungs,
  faUserMdChat as fasUserMdChat,
  faArrowAltSquareRight as fasArrowAltSquareRight,
  faSirenOn as fasSirenOn,
  faPlusSquare as fasPlusSquare,
  faVideo as fasVideo
} from '@fortawesome/pro-solid-svg-icons';

import {
  faRectanglePortrait as falRectanglePortrait,
  faUser as falUser,
  faKey as falKey,
  faHashtag as falHashtag,
  faSquare as falSquare,
  faPaperPlane as falPaperPlane,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCheckCircle as farCheckCircle,
  faCircle as farCircle,
  faSmile as farSmile,
  faTired as farTired,
  faCalendarAlt as farCalendarAlt,
  faCalendarEdit as farCalendarEdit,
  faInfoCircle as farInfoCircle,
  faSquare as farSquare,
  faExclamationTriangle as farExclamationTriangle,
  faArrowCircleRight as farArrowCircleRight,
  faArrowAltRight as farArrowAltRight,
  faArrowAltLeft as farArrowAltLeft,
  faFileAlt as farFileAlt,
  faVideo as farVideo,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronUp as farChevronUp,
  faChevronDown as farChevronDown,
  faChevronDoubleUp as farChevronDoubleUp,
  faChevronDoubleDown as farChevronDoubleDown,
  faTrashAlt as farTrashAlt,
  faArrowsAltV as farArrowsAltV,
  faArrowUp as farArrowUp,
  faArrowDown as farArrowDown,
} from '@fortawesome/pro-regular-svg-icons';

import { AppRoutingModule } from './app-routing.module';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterNavComponent } from './footer-nav/footer-nav.component';

import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResourcesComponent } from './resources/resources.component';
import { GoalsComponent } from './goals/goals.component';
import { GoalsByStatus } from './goals/goals.by-status.pipe';
import { GoalsService } from './goals/goals.service';

import { ExerciseComponent } from './exercise/exercise.component';
import { EvaluationComponent } from './evaluations/evaluation.component';

import { interceptorProviders } from './interceptors';

import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { GoalsCreateComponent } from './goals/goals-create/goals-create.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EvaluateTodayComponent } from './evaluations/evaluate-today/evaluate-today.component';
import { DashboardWeekComponent } from './dashboard/dashboard-week/dashboard-week.component';
import { WelcomeComponent } from './welcome/welcome.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Swiper
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { EvaluationsAllComponent } from './evaluations/evaluations-all/evaluations-all.component';
import { SubnavComponent } from './subnav/subnav.component';
import { EvaluationsViewComponent } from './evaluations/evaluations-view/evaluations-view.component';
import { ExerciseAddComponent } from './exercise/exercise-add/exercise-add.component';
import { ExerciseWorkoutsComponent } from './exercise/exercise-workouts/exercise-workouts.component';
import { CreateWorkoutComponent } from './exercise/create-workout/create-workout.component';
import { GetTypeRepsPipe } from './exercise/get-type-reps.pipe';
import { GoalsAboutComponent } from './goals/goals-about/goals-about.component';
import { GoalsAllComponent } from './goals/goals-all/goals-all.component';
import { GoalsViewComponent } from './goals/goals-view/goals-view.component';
import { GetThumbnailPipe } from './shared/get-thumbnail.pipe';
import { ContentSectionComponent } from './resources/content-section/content-section.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
// import { RegisterComponent } from './auth/register/register.component';
import { ForgottenPasswordComponent } from './auth/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { UpdateDetailsComponent } from './auth/update-details/update-details.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SubnavExerciseComponent } from './subnav/subnav-exercise/subnav-exercise.component';
import { SubnavEvaluationComponent } from './subnav/subnav-evaluation/subnav-evaluation.component';
import { SubnavGoalsComponent } from './subnav/subnav-goals/subnav-goals.component';
import { SubnavResourcesComponent } from './subnav/subnav-resources/subnav-resources.component';
import { SidebarComponent } from './sidebar/sidebar.component';

//import { NgCircleProgressModule } from 'ng-circle-progress';
import { DndModule } from 'ngx-drag-drop';
import { FooterComponent } from './footer/footer.component';
import { LegalComponent } from './legal/legal.component';
import { TechniquesComponent } from './techniques/techniques.component';
import { SubnavTechniquesComponent } from './subnav/subnav-techniques/subnav-techniques.component';
import { ResourceIndexComponent } from './resources/resource-index/resource-index.component';
import { ResourcePageComponent } from './resources/resource-page/resource-page.component';
import { SafeHtmlPipe } from './shared/safe-html.pipe';
import { TechniquePageComponent } from './techniques/technique-page/technique-page.component';
import { AskComponent } from './ask/ask.component';
import { ParseDatePipe } from './shared/parse-date.pipe';
import { ExerciseInfoComponent } from './exercise/exercise-info/exercise-info.component';
import { ExerciseInfoPageComponent } from './exercise/exercise-info-page/exercise-info-page.component';
import { ExerciseHowToComponent } from './exercise/exercise-how-to/exercise-how-to.component';
import { ExerciseHowToPageComponent } from './exercise/exercise-how-to-page/exercise-how-to-page.component';
import { ExerciseWorkoutViewComponent } from './exercise/exercise-workout-view/exercise-workout-view.component';
import { ActionPlanComponent } from './action-plan/action-plan.component';
import { GoalUpdateComponent } from './goals/goal-update/goal-update.component';
import { AddToHomescreenComponent } from './add-to-homescreen/add-to-homescreen.component';
import { FilterObjectArrayDayPipe } from './shared/filter-object-array-day.pipe';
import { LoginComponent } from './auth/login/login.component';
import { PatientFeedbackComponent } from './patient-feedback/patient-feedback.component';
import { VideoLibraryComponent } from './video-library/video-library.component';
import { IntroComponent } from './intro/intro.component';
import { SubnavVideoLibraryComponent } from './subnav/subnav-video-library/subnav-video-library.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

export function provideSwal() {
  return import('sweetalert2/src/sweetalert2.js'); // instead of import('sweetalert2')
}


const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#rgb(86, 170, 244)',
    },
  },
  theme: 'block',
  type: 'opt-in',
  content: {
    message: 'This website uses cookies for analytics. ',
    dismiss: 'Go away',
    deny: 'No thanks',
    allow: "That's fine",
    link: 'Learn more',
    href: 'https://cookiesandyou.com',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterNavComponent,
    DashboardComponent,
    ResourcesComponent,
    GoalsComponent,
    ExerciseComponent,
    EvaluationComponent,
    LoadingSpinnerComponent,
    GoalsCreateComponent,
    GoalsByStatus,
    EvaluateTodayComponent,
    DashboardWeekComponent,
    FilterObjectArray,
    LoginComponent,
    WelcomeComponent,
    EvaluationsAllComponent,
    SubnavComponent,
    EvaluationsViewComponent,
    ZoneToColour,
    ExerciseAddComponent,
    ExerciseWorkoutsComponent,
    CreateWorkoutComponent,
    GetTypeRepsPipe,
    GoalsAboutComponent,
    GoalsAllComponent,
    GoalsViewComponent,
    GetThumbnailPipe,
    ContentSectionComponent,
  //  RegisterComponent,
    ForgottenPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    UpdateDetailsComponent,
    SubnavExerciseComponent,
    SubnavEvaluationComponent,
    SubnavGoalsComponent,
    SubnavResourcesComponent,
    SidebarComponent,
    FooterComponent,
    LegalComponent,
    TechniquesComponent,
    SubnavTechniquesComponent,
    ResourceIndexComponent,
    ResourcePageComponent,
    SafeHtmlPipe,
    TechniquePageComponent,
    AskComponent,
    ParseDatePipe,
    ExerciseInfoComponent,
    ExerciseInfoPageComponent,
    ExerciseHowToComponent,
    ExerciseHowToPageComponent,
    ExerciseWorkoutViewComponent,
    ActionPlanComponent,
    GoalUpdateComponent,
    AddToHomescreenComponent,
    FilterObjectArrayDayPipe,
    PatientFeedbackComponent,
    VideoLibraryComponent,
    IntroComponent,
    SubnavVideoLibraryComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxSliderModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    SweetAlert2Module.forRoot({
      provideSwal,
    }),
    //NgCircleProgressModule.forRoot({}),
    SwiperModule,
    DndModule,
  ],
  providers: [CookieService, GoalsService, interceptorProviders, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      fasBooks,
      fasBullseyeArrow,
      fasCheckCircle,
      fasRectanglePortrait,
      fasWalking,
      fasTrafficLight,
      fasChevronLeft,
      fasChevronRight,
      falRectanglePortrait,
      farCheckCircle,
      farCircle,
      fasSlidersH,
      fasCircle,
      farSmile,
      farTired,
      falUser,
      falKey,
      fasTimes,
      farCalendarAlt,
      farCalendarEdit,
      farInfoCircle,
      fasCheckSquare,
      farSquare,
      fasHome,
      farExclamationTriangle,
      fasCheck,
      fasEllipsisH,
      fasBars,
      falHashtag,
      fasLungs,
      falSquare,
      farArrowCircleRight,
      farArrowAltRight,
      farFileAlt,
      falPaperPlane,
      farVideo,
      farArrowAltLeft,
      fasUserMdChat,
      farChevronLeft,
      farChevronRight,
      fasArrowAltSquareRight,
      fasSirenOn,
      farTrashAlt,
      farArrowsAltV,
      fasPlusSquare,
      farArrowUp,
      farArrowDown,
      farChevronUp,
      farChevronDown,
      farChevronDoubleUp,
      farChevronDoubleDown,
      fasVideo
    );
  }
}
