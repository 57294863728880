import { GoalsService } from './../goals.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-goals-view',
  templateUrl: './goals-view.component.html',
  styleUrls: ['./goals-view.component.scss'],
})
export class GoalsViewComponent implements OnInit {
  goal: any;
  public errors: string[];
  isFetching = false;
  error;

  constructor(
    private route: ActivatedRoute,
    private goalsService: GoalsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.goalsService.fetch(this.route.snapshot.params['id']).subscribe(
      (goal) => {
        this.goal = goal;
      },
      (error) => {
        // this.errors.push(error.message);
      }
    );
  }

  onSetGoalStatus(status: number): void {

    if(this.goal.status==3){
   
    }
    else{
    this.goal.status = status;
    // now update it
    this.goalsService.updateGoal(this.goal).subscribe(
      (responseData) => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Goal Status Updated',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
      },
      (error) => {
        // this.errors.push(error.message);
      }
    );
  }
  }

  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.goalsService.delete(this.goal.goal_id).subscribe(
          (responseData) => {
            Swal.fire('Deleted', 'Your goal has been deleted.', 'success');
            this.router.navigate(['/goals']);
          },
          (error) => {
            this.isFetching = false;
            this.error = error.message;
            Swal.fire('Deleted!', 'There was an error.', 'error');
          }
        );
      }
    });
  }
}
