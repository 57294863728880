import { UserSettingsService } from './../services/user-settings.service';
import { Component, OnInit } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.scss'],
})
export class ActionPlanComponent implements OnInit {
  isFetching = false;
  statements = [
    {id:1, title:'I am going to use the lean forward recovery position', link:'/techniques/49'},
    {id:2, title:'I am going to use the breathing recovery technique; fan, forward lean, focus', link:'/techniques/49'},
    {id:3, title:'I am going to use my fan Focus and  gently breathing out', link:'/techniques/47'},
    {id:4, title:'I am going to self-reassure my myself by saying; I can do this - I am doing it now I don\'t need to be frightened - I am OK', link:''},
    {id:5, title:'I am going to use my fan Focus and use purse lipped breathing', link:'/techniques/46'},
    {id:6, title:'I am not going to panic', link:''},
    {id:7, title:'I am going to focus on breathing control', link:'/techniques/45'},
    {id:8, title:'I am going to use the calming hand', link:'/techniques/51'},
    {id:9, title:'I will read the breathlessness poem', link:'/techniques/52'},
  ];
  step = null;
  selectedStatements = [];
  currentPlan = null;
  textAreasList: any = ['text_area1'];

  private currentDraggableEvent: DragEvent;
  private currentDragEffectMsg: string;
  constructor(private userSettingsService: UserSettingsService) {}

  ngOnInit(): void {
    this.userSettingsService.fetchAll().subscribe((responseData) => {
      if (responseData.currentPlan) {
        this.currentPlan = JSON.parse(responseData.currentPlan);
        this.selectedStatements = this.currentPlan;

        this.currentPlan.forEach((plan) => {
          let addToPlan = true;
          this.statements.forEach((_plan)=> {
            if(_plan.id == plan.id){
              addToPlan = false;
            }
          });
          if(addToPlan){
            this.statements.push(plan);
          }
        });

        this.step = 0;
      } else {
        this.step = 1;
      }
    });

    polyfill({
      // use this to make use of the scroll behaviour
      dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
    });

    window.addEventListener('touchmove', () => {}, { passive: false });
  }

  onSelectStatement(statement): void {
    if (this.selectedStatements.indexOf(statement) === -1) {
      this.selectedStatements.push(statement);
    } else {
      const index = this.selectedStatements.indexOf(statement);
      if (index > -1) {
        this.selectedStatements.splice(index, 1);
      }
    }
  }

  onDragStart(event: DragEvent) {
    this.currentDragEffectMsg = '';
    this.currentDraggableEvent = event;
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    this.currentDragEffectMsg = `Drag ended with effect "${effect}"!`;

    if (effect === 'move') {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDragEnd(event: DragEvent) {
    this.currentDraggableEvent = event;
  }

  onDrop(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === 'copy' || event.dropEffect === 'move')) {
      let index = event.index;

      if (typeof index === 'undefined') {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }

  onNext() {
    if (this.step === 2) {
      this.textAreasList.forEach((textarea) => {
        let elem: HTMLInputElement;
        elem = document.querySelector('#' + textarea);
        if (elem.value) {
          this.selectedStatements.push({id:0, title:elem.value, link:''});
        }
      });
    }
    this.step++;
  }
  onEdit() {
    this.step = 1;
  }
  onSave() {
    this.userSettingsService
      .saveSetting('currentPlan', JSON.stringify(this.selectedStatements))
      .subscribe((responeData) => {
        this.step = 0;
        this.currentPlan = this.selectedStatements;
      });
    this.userSettingsService
      .saveSetting('setupActionPlan', '1')
      .subscribe((responeData) => {
      });
  }

  onAddTextarea() {
    this.textAreasList.push('text_area' + (this.textAreasList.length + 1));
  }
  getLink(statement:any){
    let link;
    this.statements.forEach((_statement)=>{
      if(_statement.id == statement.id && _statement.link){
        link = _statement.link;
      }
    });
    return link;
  }
}
