import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEventType,
} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError, tap } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import { Exercise } from './exercise.model';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ExercisesService {
  error = new Subject<string>();

  constructor(
    private http: HttpClient,
  ) {
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/exercises/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const exercise = new Exercise(
            responseData.exercise_id,
            responseData.name,
            responseData.exercise_type,
            responseData.video,
            responseData.created,
            responseData.modified
          );
          return exercise;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetchExercises() {
    let searchParams = new HttpParams();
    searchParams = searchParams.append('sort', 'exercise_id');
    searchParams = searchParams.append('order', 'ASC');
    return this.http
      .get<any>(environment.apiUrl + '/exercises', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const exerciseArray: Exercise[] = [];
          responseData['_embedded'].exercises.forEach((exercise) => {
            exerciseArray.push(exercise);
          });
          return exerciseArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
}
