import { Goal } from 'src/app/goals/goal.model';
import { ActivatedRoute, Router } from '@angular/router';
import { GoalsService } from './../goals.service';
import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-goal-update',
  templateUrl: './goal-update.component.html',
  styleUrls: ['./goal-update.component.scss']
})
export class GoalUpdateComponent implements OnInit {

  isFetching = false;
  goal: Goal;
  goalId;
  submitted = false;
  error;
  bsValue;

   constructor(
    private route: ActivatedRoute,
    private goalsService: GoalsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.goalsService.fetch(this.route.snapshot.params['id']).subscribe(
      (goal) => {
        this.goal = goal;
        if(goal.status==3){
          this.router.navigate(['/goals']);
        }
        this.bsValue = new Date(goal.review);
      },
      (error) => {
        // this.errors.push(error.message);
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    this.goal.review = this.bsValue;
    this.goalsService.updateGoal(this.goal).subscribe(
      (responseData) => {
        Swal.fire({
          title: 'Goal Updated',
          icon: 'success',
        });
        this.router.navigate(['/goals']);
      },
      (error) => {
        // this.errors.push(error.message);
      }
    );
  }
}
